import React from 'react'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'


import VisaMasterCardIcon from '../../../static/images/donate/visa-mastercard-icon.png'

const useStyles = makeStyles(theme => ({
  item: {
    fontSize: 16,
    color: '#4A4A4A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
    lineHeight: 1.8
  },
  image: {
    width: 190,
  }
}))

const DonateButton = withStyles((theme) => ({
  root: {
    fontSize: 14,
    color: '#1D6320',
    borderRadius: 0,
    width: 270,
    height: 50,
    backgroundColor: '#AFD53D',
    '&:hover': {
      backgroundColor: '#9EC42D',
    },
  },
}))(Button);


const PaypalInfo = () => {
  const classes = useStyles()
  const { t } = useTranslation('donation_guide')
  return (
    <List component="nav" aria-label="props list">
      <Divider/>
      <ListItem className={classes.item}>
        {t(`You can set up your Paypal account as a recurring transfer to VietSeeds, based on your preference (monthly, quarterly, bi-annually or just one-time payment)`)}
        <br />
        {t('For Paypal transfer, please make the payment to our PayPal account at info@vietseed.org')}
      </ListItem>
      <ListItem className={classes.item}>
        <DonateButton variant='contained' color='primary' className={classes.button}
          href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TEL2K9JCK3URE'
          target='_blank'
        >
          {t(`Donate`)}
        </DonateButton>
        <img src={VisaMasterCardIcon} alt='visa-master-card' className={classes.image} />
      </ListItem>
    </List>
  )
}

export default PaypalInfo