import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { Img } from 'react-image'
import { useTranslation } from 'react-i18next'

import ListWrapper from '../../StudentProfile/components/ListWrapper'

import StudentImagePlaceholder from '../../../static/images/logo.png'
import { RegionChoices, GenerationChoices } from '../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  body: {
    flex: 1,
    background: '#fff',
    padding: 20,
  },
  image: {
    width: '90%',
    height: '100%',
    borderRadius: 18,
    objectFit: 'contain',
    maxHeight: 520,
  },
  studentName: {
    color: '#85BE1A',
    fontSize: 28,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 20,
  }
}))

const BriefStudentProfile = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_profile')
  const { provinceList, universityList } = props

  const displayFields = [
    { name: 'display_name', noLabel: true, className: classes.studentName, },
    { name: 'university_id', noLabel: true, options: universityList },
    { name: 'university_year', },
    { name: 'study_major', },
    { name: 'province_id', options: provinceList },
    { name: 'vietseeds_generation', options: GenerationChoices, },
    { name: 'vietseeds_region', options: RegionChoices, },
  ]

  const { studentInfos } = props
  const { profile_picture_url } = studentInfos

  return (
    <Grid container item xs={12} className={classes.root}>
      <Divider className={classes.divider} />
      <Grid container item xs={12} md={5}>
        <Img src={[profile_picture_url, StudentImagePlaceholder]} alt='student' className={classes.image} />
      </Grid>
      <Grid container item xs={12} md={7} direction="column">
        <ListWrapper data={studentInfos} fields={displayFields} t={t} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({ general, student }) => {
  return {
    provinceList: general.provinceList,
    universityList: general.universityList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BriefStudentProfile)
