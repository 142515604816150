import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Loading from '../../components/Loading'
import { getStudentInfos } from '../../redux/actions/studentAction'

import SummarySection from './components/SummarySection'
import ProfileContainer from './components/ProfileContainer'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    },
  },
  titleContainer: {
    textAlign: 'center',
  },
  title: {
    fontSize: 42,
    color: '#000',
    fontWeight: 'bold',
    lineHeight: '56px',
  },
  titleHighlight: {
    color: '#85BE1A',
  },
}))

const StudentProfile = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_profile')
  const { getStudentInfosHandler, isGettingStudentInfos,
    provinceList, universityList,
    studentInfos 
  } = props
  let { id } = useParams()
  id = parseInt(id)

  useEffect(() => {
    getStudentInfosHandler({ id })
  }, [id])
  
  if (isGettingStudentInfos) {
    return <Loading isLoading={isGettingStudentInfos}/>
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {t(`Student`)} <span className={classes.titleHighlight}>{t(`Profile`)}</span>
        </div>
      </div>
      <SummarySection studentInfos={studentInfos} provinceList={provinceList} universityList={universityList}/>
      <ProfileContainer studentInfos={studentInfos} provinceList={provinceList} universityList={universityList}/>
    </div>
  )
}


const mapStateToProps = ({ general, student }) => {
  return {
    studentInfos: student.studentInfos,
    isGettingStudentInfos: student.isGettingStudentInfos,

    provinceList: general.provinceList,
    universityList: general.universityList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getStudentInfosHandler: getStudentInfos,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfile)