import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next'

import TutorialEn0 from '../../static/images/tutorial/tutorial_en_0.png'
import TutorialEn1 from '../../static/images/tutorial/tutorial_en_1.png'
import TutorialVi0 from '../../static/images/tutorial/tutorial_vi_0.png'
import TutorialVi1 from '../../static/images/tutorial/tutorial_vi_1.png'

function getSteps() {
  return ['overview', 'detail'];
}

const stepImageSrc = {
  vi: [
    TutorialVi0,
    TutorialVi1
  ],
  en: [
    TutorialEn0,
    TutorialEn1,
  ]
}

const READ_TUTORIAL = 'READ_TUTORIAL'

const Tutorial = (props) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation('tutorial');
  const readTutorial = localStorage.getItem(READ_TUTORIAL)
  const [open, setOpen] = React.useState(!!!readTutorial);

  let currentLanguage = i18n.language

  const handleClose = () => {
    localStorage.setItem(READ_TUTORIAL, true)
    setOpen(false);
  };
  
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      className={classes.root}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        {t('tutorial')}
      </DialogTitle>
      <DialogContent>
        <div className={classes.contentRoot}>
          <div className={classes.imageContainer}>
            <img src={stepImageSrc[currentLanguage][activeStep]} alt='tutorial-step' className={classes.tutorialImage}/>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="secondary">
          {t('skip')}
        </Button>
        <Button disabled={activeStep === 0} onClick={handleBack} color="warning">
          {t('back')}
        </Button>
        {activeStep === totalSteps() - 1 ? 
            <Button onClick={handleClose} color="primary">
              {t('finish')}
            </Button>
          :
            <Button onClick={handleComplete} color="primary">
              {t('next')}
            </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  contentRoot: {
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    textAlign: 'center'
  },
  tutorialImage: {
    width: '80%',
    height: 'auto'
  }
}));

export default Tutorial