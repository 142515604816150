import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

const FurtherInformationPopUp = (props) => {
  const { t } = useTranslation('donation_guide')
  const { isOpen, handleClose } = props
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle>
        {t(`Overall guidance`)}
      </DialogTitle>
      <DialogContent style={{ lineHeight: 1.7 }}>
        <ul>
          <li>{t(`When making the transfer, please specify "VietSeeds Scholarship - From [your name] to sponsor for [your student's name]".`)}</li>
          <li>{t(`For donations in the US, you can transfer us the money either via online bank account, paypal, check or online bill pay.`)}</li>
          <li>{t(`For donations in Vietnam, you can transfer us the money via VietSeeds Foundation account`)}</li>
          <li>{t(`Once you have made the transfer, please help drop us a note`)}</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          {t(`Close`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FurtherInformationPopUp