import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#1D6320',
    backgroundColor: '#AFD53D',
    width: 140,
    height: 40,
    fontSize: 12,
    fontWeight: 'bold',
    borderRadius: 10,
    overflow: 'hidden',
    maxWidth: '49%',
    lineHeight: 1.5,
    textAlign: 'center',
  },
  amountFade: {
    position: 'absolute',
    width: '100% !important',
    height: '100% !important',
    '& div': {
      width: '100%',
      height: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
  }
}))

const SponsorButton = (props) => {
  const { label, amount, ...others } = props
  const classes = useStyles()
  const [ isHovering, setIsHovering ] = useState(false)

  const { t } = useTranslation('student_list')

  return (
    <Button 
      variant="contained" size="small" 
      className={classes.button} 
      onMouseLeave={() => setIsHovering(false)}
      onMouseOver={() => setIsHovering(true)}
      {...others}
    >
      {label}
      <Fade
        in={isHovering}
        {...(isHovering ? { timeout: 0 } : { timeout: 1000 })}
        className={classes.amountFade}
      >
        <Paper elevation={0}>
          {`${t('package')} $${amount}`}
        </Paper>
      </Fade>
    </Button>
  )
}

export default SponsorButton