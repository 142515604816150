import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Loading from '../../components/Loading'
import Filter from './components/Filter'
import Pagination from './components/PaginationContainer'
import StudentProfileThumbnail from './components/StudentProfileThumbnail/index'

import { getStudentList } from '../../redux/actions/studentAction'

import BannerImage from '../../static/images/banner.png'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 50px',
  },
  bannerImage: {
    width: '100%',
    height: 'auto'
  },
  titleContainer: {
    textAlign: 'center',
  },
  title: {
    fontSize: 42,
    color: '#000',
    fontWeight: 'bold',
    lineHeight: '56px',
  },
  titleHighlight: {
    color: '#85BE1A',
  },
}))

const StudentList = (props) => {
  const { t } = useTranslation('student_list')
  const classes = useStyles()

  const { isGettingStudentList, total, 
    studentList, provinceList, universityList,
    getStudentListHandler,
  } = props

  const location = useLocation()
  const query = new URLSearchParams(location.search);
  const filter = JSON.parse(query.get('filter')) || {};
  // TODO: generalize logic here for array options filtering
  if (filter.province_id) {
    filter.province_ids = [filter.province_id]
  } else {
    delete filter.province_ids
  }
  const page = parseInt(query.get('page') || '1', 10);
  
  useEffect(() => {
    getStudentListHandler({
      page,
      perPage: 9,
      filter,
    })
  }, [location]);
  
  if (isGettingStudentList) {
    return (
      <Loading isLoading/>
    )
  }

  return (
    <>
      <img src={BannerImage} alt='banner' className={classes.bannerImage}/>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            {t(`Our`)} <span className={classes.titleHighlight}>{t(`Students`)}</span>
          </div>
        </div>
        <Filter filter={filter} query={query} provinceList={provinceList}/>
        <Grid container spacing={10} justify={'center'}>
          {
            studentList.map(student => {
              return (
                <StudentProfileThumbnail data={student} key={`student-${student.id}`}
                  provinceList={provinceList}
                  universityList={universityList}
                />
              )
            })
          }
        </Grid>
        <Pagination total={total}/>
      </div>
    </>
  )
}


const mapStateToProps = ({ student, general }) => {
  return {
    provinceList: general.provinceList,
    universityList: general.universityList,
    isGettingStudentList: student.isGettingStudentList,
    studentList: student.studentList,
    total: student.total,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getStudentListHandler: getStudentList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StudentList)