import StudentList from './pages/StudentList/index'
import MyStudentList from './pages/MyStudentList/index'
import StudentProfile from './pages/StudentProfile/index'
import DonationGuide from './pages/DonationGuide/index'
import DonateForm from './pages/DonateForm/index'
import Login from './pages/Login/index'
import Register from './pages/Register/index'

const AppRoutes = [
  { path: "/student-list", component: StudentList, exact: true, },
  { path: "/student/:id", component: StudentProfile, exact: true, requiredAuth: true },
  { path: "/donation-guide", component: DonationGuide, exact: true, },
  { path: "/my-student-list", component: MyStudentList, exact: true, requiredAuth: true },
  { path: "/donate", component: DonateForm, exact: true, requiredAuth: true },
  { path: "/login", component: Login, exact: true, requiredUnAuth: true },
  { path: "/register", component: Register, exact: true, requiredUnAuth: true },
]

export default AppRoutes