import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  item: {
    fontSize: 16,
    color: '#4A4A4A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  propName: {
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  propValue: {
    textAlign: 'right',
    lineHeight: 1.5,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.8,
    },
  },
  alignLeft: {
    textAlign: 'left',
  }
}))

const PropField = (props) => {
  const { label, value, noLabel, className, ...others } = props
  const classes = useStyles()

  return (
    <ListItem className={clsx(classes.item, className)} {...others}>
      {!noLabel && <span className={classes.propName}>{label}</span>}
      <span className={clsx(classes.propValue, noLabel && classes.alignLeft)}>{value || '-'}</span>
    </ListItem>
  )
}

export default PropField