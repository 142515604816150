import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { Section } from 'react-scroll-section';

import AcademicInfoBeforeF10 from './AcademicInfoBeforeF10'
import AcademicInfoFromF10 from './AcademicInfoFromF10'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
}))

const AcademicInfo = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_profile')
  const { universityList } = props

  const { studentInfos } = props
  const generation = get(studentInfos, 'vietseeds_generation', 0)

  return (
    <Section id="b-academic-info" className={classes.root}>
      { generation < 10 ?
        <AcademicInfoBeforeF10
          studentInfos={studentInfos}
          universityList={universityList}
        />
        :
        <AcademicInfoFromF10
          studentInfos={studentInfos}
          universityList={universityList}
        />
      }
    </Section>
  )
}

export default AcademicInfo
