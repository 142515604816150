import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Route,
  Redirect,
} from "react-router-dom";
import authProvider from './connectors/AuthProvider';
import reactToastify from './plugin/react-toastify';
import { refreshAuthState } from './redux/actions/accountAction';

const RequiredAuthRoute = ({ children, refreshAuthStateHandler, ...rest }) => {
  const { authorized } = rest
  const { t } = useTranslation('login')
  useEffect(() => {
    if (!authProvider.checkAuth()) {
      reactToastify.error(t('expired_access_token'))
      authProvider.logout();
      setTimeout(() => {
        refreshAuthStateHandler()
      }, 100)
    }
  }, [])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authorized ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refreshAuthStateHandler: refreshAuthState,
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(RequiredAuthRoute)
