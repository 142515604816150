import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { Section } from 'react-scroll-section';

import EssayField from '../EssayField'
import { studentPersonalSharingFieldsByGeneration} from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
}))

const BeforeF10Section = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_profile')
  const { studentInfos } = props

  return (
    <Section id="c-personal-sharing" className={classes.root}>
      <EssayField
        question={t('1. Personal story')}
        answer={get(studentInfos, 'extra_data.essay.essay', '')}
      />
      <EssayField
        question={t('2. Family story')}
        answer={get(studentInfos, 'extra_data.family.family_story', '')}
      />
    </Section>
  )
}

const FromF10Section = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_profile')
  const { studentInfos, generation } = props

  const personalSharingFields = get(studentPersonalSharingFieldsByGeneration, generation, [])

  return (
    <Section id="c-personal-sharing" className={classes.root}>
      {personalSharingFields.map((field, index) => {
        const { question_id, name, defaultAnswer = ''} = field
        return (
          <EssayField
            key={name}
            question={`${index + 1}. ${t(question_id)}`}
            answer={get(studentInfos, name, defaultAnswer)}
          />
        )
      })
      }
    </Section>
  )
}

const PersonalSharing = (props) => {
  const { studentInfos } = props
  const generation = get(studentInfos, 'vietseeds_generation', 0)

  if (generation >= 10) {
    return <FromF10Section {...props} generation={generation}/>
  } else {
    return <BeforeF10Section {...props} />
  }
}

export default PersonalSharing
