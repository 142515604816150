import { call, put, takeLatest } from 'redux-saga/effects'
import { get, set, concat } from 'lodash'

import {
  getStudentList,
  getStudentListSuccess,
  getStudentListFail,

  getStudentInfos,
  getStudentInfosSuccess,
  getStudentInfosFail,
} from '../actions/studentAction'

import AuthProvider from '../../connectors/AuthProvider'
import splitTextToArray from '../../helpers/splitTextToArray'
import restConnector from '../../connectors/RestConnector'

function* getStudentListSaga({ payload }) {
  try {
    const { page = 1, perPage = 9, filter } = payload
    const response = yield call(restConnector.post, `/student/get_student_ids`, {
      ...filter,
      vietseeds_student_type: 1, // only get current students
      order_by: 'sponsor_status', // prioritize non-sponsored students
    })
    const studentIds = get(response, 'data.reply.student_ids', [])

    const pagedStudentIds = studentIds.slice((page - 1) * perPage, page * perPage)
    const getStudentSummariesUrl = AuthProvider.checkAuth() ?
      '/sponsor/get_student_summaries'
      : '/student/get_student_summaries'
    const summaryResponse = yield call(restConnector.post, getStudentSummariesUrl, {
      student_ids: pagedStudentIds,
    })

    const studentList = get(summaryResponse, 'data.reply.student_summaries', [])
    yield put(getStudentListSuccess({
      studentList,
      total : studentIds.length,
    }))
  } catch (error) {
    yield put(getStudentListFail(error))
  }
}

function* getStudentInfosSaga({ payload }) {
  try {
    const { id } = payload
    const getStudentInfosUrl = AuthProvider.checkAuth() ?
      '/sponsor/get_student_infos'
      : '/student/get_student_infos'
    const response = yield call(restConnector.post, getStudentInfosUrl, {
      student_ids: [id]
    })
    let studentInfos = get(response, 'data.reply.student_summaries[0]', {})

    const generation = get(studentInfos, 'vietseeds_generation', 0)

    if (generation < 10) {
      // parse achievements
      const achievementsText = get(studentInfos, 'extra_data.university.achievement', '')
      const scholarshipsText = get(studentInfos, 'extra_data.university.scholarship', '')
      const achievementsArr = splitTextToArray(achievementsText + '\n' + scholarshipsText)
      set(studentInfos, 'extra_data.university.achievements', achievementsArr)
  
      // parse extra_activity
      const extraActivityText = get(studentInfos, 'extra_data.university.extra_activity', '')
      const extraActivityArr = splitTextToArray(extraActivityText)
      set(studentInfos, 'extra_data.university.extra_activity', extraActivityArr)
    } else {
      const highSchoolAchievements = get(studentInfos, 'extra_data.achievement.high_school', [])
      const universityAchievements = get(studentInfos, 'extra_data.achievement.university', [])
      const scholarships = get(studentInfos, 'extra_data.scholarship', [])
      set(studentInfos, 'extra_data.achievements', concat(highSchoolAchievements, universityAchievements, scholarships))
    }

    yield put(getStudentInfosSuccess({ studentInfos }))
  } catch (error) {
    yield put(getStudentInfosFail(error))
  }
}

function* getStudentListWatcher() {
  yield takeLatest(getStudentList, getStudentListSaga)
}

function* getStudentInfosWatcher() {
  yield takeLatest(getStudentInfos, getStudentInfosSaga)
}

export {
  getStudentListWatcher,
  getStudentInfosWatcher,
}