import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Skeleton from '@material-ui/lab/Skeleton'
import { Img } from 'react-image'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import PropsList from './PropsList'
import SponsorButton from './SponsorButton'
import Link from '../../../../components/Link'

import mapChoicesToValue from '../../../../helpers/mapChoicesToValue'
import StudentImagePlaceholder from '../../../../static/images/logo.png' 
import { RegionChoices, GenerationChoices, ScholarshipPackage } from '../../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  paper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 18,
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    flex: 1,
    background: '#fff',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    height: 280,
    objectFit: 'contain',
  },
  studentName: {
    color: '#85BE1A',
    fontSize: 18,
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pending: {
    marginTop: 10,
    fontSize: 12,
    color: 'gray',
  }
}))

const StudentProfileThumbnail = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_list')
  const { provinceList, universityList } = props

  const displayFields = [
    { name: 'university_id', label: 'university', noLabel: true, options: universityList },
    { name: 'university_year', label: 'class' },
    { name: 'study_major', label: 'major', },
    { name: 'province_id', label: 'hometown', options: provinceList},
    { name: 'vietseeds_generation', label: 'generation', options: GenerationChoices, },
    { name: 'vietseeds_region', label: 'region', options: RegionChoices, },
  ]

  const { data } = props
  const { id, profile_picture_url, sponsor_status, has_pending_sponsor } = data
  
  const mapDisplayFieldsToProps = () => {
    return displayFields.map(field => {
      const { name, label, options, ...others } = field
      return ({
        name,
        label: t(label),
        value: mapChoicesToValue(options, get(data, name, '')),
        ...others,
      })
    })
  }

  const propsList = mapDisplayFieldsToProps()

  return (
    <Grid container item xs={12} sm={6} md={4} className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <Link to={`/student/${id}`}>
          <Img src={[profile_picture_url, StudentImagePlaceholder]} alt='student' className={classes.image}
            loader={
              <Skeleton variant="rect" className={classes.image} animation="wave">
              </Skeleton>
            }
          />
        </Link>
        <div className={classes.body}>
          <Link to={`/student/${id}`}>
            <div className={classes.studentName} href={`/student/${id}`}>
              {get(data, 'display_name', '')}
            </div>
          </Link>
          <PropsList propsList={propsList} />
          <div style={{ flex: 1 }}></div>
          <div className={classes.buttonContainer}>
            <SponsorButton
              label={t('half-scholarship')} 
              amount={ScholarshipPackage.half} 
              disabled={Boolean(sponsor_status && sponsor_status === 2)}
              href={`/donate?student_id=${id}&amount=half`}
            />
            <SponsorButton 
              label={t('full-scholarship')} 
              amount={ScholarshipPackage.full} 
              disabled={Boolean(sponsor_status && sponsor_status !== 0)}
              href={`/donate?student_id=${id}&amount=full`}
            />
          </div>
          {has_pending_sponsor &&
            <div className={classes.pending}>
              {t(`pending-for-sponsor-confirmation`)}
            </div>
          }
        </div>
      </Paper>
    </Grid>
  )
}

export default StudentProfileThumbnail
