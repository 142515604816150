import React from 'react'
import { ErrorMessage } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from "@material-ui/core/FormHelperText";
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import colors from '../constants/colors'


const useStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 15,
    color: '#4A4A4A',
    fontSize: 16,
    '& .MuiInputBase-root': {
      background: '#fff',
      borderRadius: 30,
    },
  },
  error: {
    margin: 0,
    paddingLeft: 0,
    width: '100%',
    color: colors.secondaryMain,
  },
  helperText: {
    margin: 0,
    paddingLeft: 20,
  },
  label: {
    flex: 1,
  },
  inputContainer: {
    width: '70%',
  },
  checkbox: {
    marginRight: 50,
  }
}))

const CustomCheckbox = (props) => {
  const { label, field = {}, form, choices, helperText, ...others } = props
  const classes = useStyles()
  const { t: tYup } = useTranslation('yup')

  const handleChange = (value) => () => {
    const { setFieldValue } = form
    setFieldValue(field.name, value)
  }

  return (
    <div className={classes.rootContainer} >
      <span
        htmlFor={get(field, 'name', '')}
        className={classes.label}
      >
        {label}
      </span>
      <FormGroup row className={classes.inputContainer} {...others}>
        {choices.map(choice => {
          return (
            <FormControlLabel
              className={classes.checkbox}
              control={<Checkbox 
                checked={field.value === choice.id} 
                onChange={handleChange(choice.id)} 
                name={choice.name} 
                color="primary"
              />}
              label={choice.name}
            />
          )
        })}
        {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
        <ErrorMessage name={get(field, 'name', '')}
          render={(msg) =>
            <FormHelperText error className={classes.error}>
              {tYup(msg)}
            </FormHelperText>
          }
        />
      </FormGroup>
    </div>
  )
}

export default CustomCheckbox
