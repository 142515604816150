import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FurtherInformationPopUp from './FurtherInformationPopUp'

const FurtherInformation = () => {
  const { t } = useTranslation('donation_guide')
  const [ isPopUpOpen, setPopUpOpen ] = useState(false)
  return (
    <div style={{ fontSize: 16, color: '#4A4A4A', lineHeight: 1.5 }}>
      <div>
        {t('For')}
        <span style={{ fontWeight: 'bold' }}>{t(` FURTHER DETAILED INFORMATION `)}</span>
        {t(` on our donation instruction, please `)}
        <span onClick={() => setPopUpOpen(true)} style={{ color: '#AFD53D', cursor: 'pointer', textTransform: 'underline' }}>
          {t('click here')}
        </span>
      </div>

      <FurtherInformationPopUp isOpen={isPopUpOpen} handleClose={() => setPopUpOpen(false)}/>
    </div>
  )
}

export default FurtherInformation