/* eslint-disable no-use-before-define */
import React from 'react';
import { ErrorMessage, useField, useFormikContext } from 'formik'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import colors from '../constants/colors'

const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 15,
    color: '#4A4A4A',
    fontSize: 16,
  },
  error: {
    margin: 0,
    paddingLeft: 20,
    color: colors.secondaryMain,
  },
  helperText: {
    margin: 0,
    paddingLeft: 20,
  },
  label: {
    flex: 1,
  },
  inputContainer: {
    width: '70%',
  },
  autoComplete: {
    width: '100%',
  },
  input: {
    height: 50,
    boxSizing: 'border-box',
    paddingLeft: 20,
    paddingRight: 20,
    background: '#fff',
    borderRadius: 30,
  },
});

const FormAutoComplete = (props) => {
  const { 
    field = {}, form, label, required, helperText, fullWidth, 
    options, getOptionLabel, renderOption, getOptionSelected,
    ...others
  } = props

  const [, meta,] = useField(field.name);
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();
  const { t: tYup } = useTranslation('yup')

  return (
    <FormControl 
      fullWidth={fullWidth} 
      className={classes.rootContainer} 
      error={meta.touched && meta.error}
      {...others}
    >
      <span 
        htmlFor={get(field, 'name', '')}
        className={classes.label}
      >
        {label}
      </span>
      <div className={classes.inputContainer}>
        <Autocomplete
          {...field}
          onChange={(e, value) => {setFieldValue(field.name, value)}}
          id={field.name}
          options={options}
          classes={{
            root: classes.autoComplete,
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          getOptionSelected={getOptionSelected}
          renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                required={required}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                  style: { padding: '20px 0px', }
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  className: classes.input,
                }}
              />
          )}
        />
        {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
        <ErrorMessage name={get(field, 'name', '') + '.id'}
          render={(msg) =>
            <FormHelperText error className={classes.error}>
              {tYup(msg)}
            </FormHelperText>
          }
        />
      </div>
    </FormControl>
  );
}

export default FormAutoComplete