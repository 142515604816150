import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import clsx from 'clsx'
import { useScrollSections } from 'react-scroll-section';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    position: 'sticky',
    top: 100,
  },
  item: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4A4A4A',
    borderRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
  },
  selectedItem: {
    backgroundColor: '#AFD53D !important',
    color: '#fff',
  }
}))

const Menu = () => {
  const sections = useScrollSections();
  const classes = useStyles()
  const { t } = useTranslation('student_profile')

  return (
    <List className={classes.root}>
      {sections.map(({ id, onClick, selected }) => (
        <ListItem 
          key={id} onClick={onClick} selected={selected} button
          className={clsx(classes.item, selected && classes.selectedItem)}
        >
          {t(id)}
        </ListItem>
      ))}
    </List>
  );
};

export default Menu