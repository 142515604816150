import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash'

import PlainText from '../../../components/TableShowFields/PlainText'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
  },
  table: {
    width: '100%',
    fontSize: 16,
    color: '#4A4A4A',
  },
  header: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}))

const TableWrapper = (props) => {
  const { t, tableName, fields, data } = props
  const classes = useStyles()

  const rows = get(data, tableName, [])

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label={tableName}>
        <TableHead>
          <TableRow>
            {fields.map(field => {
              const { name, align = 'left' } = field
              return (
                <TableCell key={name} align={align} className={classes.header}>{t(`${tableName}.${name}`)}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, id) => (
            <TableRow key={`table-${tableName}-${id}`}>
              {fields.map(field => {
                const { name, align = 'left', component: Component = PlainText, ...others } = field
                return (
                  <TableCell key={name} align={align}>
                    <Component value={get(row, name, '')} {...others}/>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableWrapper