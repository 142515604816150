import swal from 'sweetalert'
import { get } from 'lodash'

import { ResponseCode } from '../constants/constants'

const mapErrorToAlert = {
  [ResponseCode.SUCCESS]: {
    title: 'Thao tác thành công',
    text: '',
    icon: 'success',
  },

  [ResponseCode.ERROR_PARAMS]: {
    title: 'Thiếu thông tin',
    text: 'Vui lòng kiểm tra điền đầy đủ thông tin và thử lại',
    icon: 'error',
  },
  [ResponseCode.ERROR_FORBIDDEN]: {
    title: 'Không có quyền truy cập',
    text: `Bạn không có quyền truy cập tính năng này! Nếu bạn nghĩ có sai sót,
    vui lòng liên hệ trang facebook: https://www.facebook.com/Vietseed/ 
    để được hỗ trợ.`,
    icon: 'error',
  },
  [ResponseCode.ERROR_CAPTCHA]: {
    title: 'Captcha đã hết hạn',
    text: 'Vui lòng xác minh captcha lại để tiếp tục!',
    icon: 'error',
  },

  [ResponseCode.ERROR_INVALID_FILE_FORMAT]: {
    title: 'Định dạng file không được hỗ trợ',
    text: 'Kiểm tra lại định dạng file đúng yêu cầu!',
    icon: 'error',
  }, 
  [ResponseCode.ERROR_IMAGE_UPLOAD_FAILED]: {
    title: 'Có lỗi xảy ra trong quá trình upload ảnh',
    text: 'Vui lòng thử lại sau hoặc chọn ảnh khác!',
    icon: 'error',
  }, 
  [ResponseCode.ERROR_FILE_UPLOAD_FAILED]: {
    title: 'Có lỗi xảy ra trong quá trình upload file',
    text: 'Vui lòng thử lại sau hoặc chọn file khác!',
    icon: 'error',
  }, 

  [ResponseCode.ERROR_DUPLICATED_APPLICATION]: {
    title: 'Email đã được đăng ký',
    text: 'Vui lòng kiểm tra email (kể cả hộp thư SPAM) và làm theo hướng dẫn để hoàn tất form đăng ký. Nếu vẫn không nhận được email, bấm chọn nút "Không nhận được email" để gửi lại.',
    icon: 'error',
  }, 
  [ResponseCode.ERROR_INVALID_APPLICATION_CREDENTIAL]: {
    title: 'Sai email hoặc passcode',
    text: 'Vui lòng kiểm tra lại email và passcode!',
    buttons: {
      cancel: {
        text: "Đăng ký",
        value: false,
        visible: true,
        closeModal: true,
      },
      confirm: {
        text: "Thử lại",
        value: true,
        visible: true,
        closeModal: true
      }
    },
    icon: 'error',
    closeOnClickOutside: false,
  }, 
  [ResponseCode.ERROR_NOT_ALLOWED_TO_EDIT_APPLICATION]: {
    title: 'Lưu ý',
    text: 'Bạn đã nộp đơn và không thể chỉnh sửa nữa',
    icon: 'warning',
  }, 
  [ResponseCode.ERROR_NOT_ALLOWED_TO_REGISTER_APPLICATION]: {
    title: 'Hết hạn nộp đơn',
    text: `Đã hết hạn nộp đơn! Nếu bạn nghĩ có sai sót, 
      vui lòng liên hệ trang facebook: https://www.facebook.com/Vietseed/ 
    để được hỗ trợ.`,
    icon: 'error',
  },
  [ResponseCode.ERROR_SUBMIT_APPLICATION_FAILED]: {
    title: 'Có lỗi xảy ra khi nộp đơn',
    text: 'Vui lòng kiểm tra và điền đầy đủ các thông tin để nộp đơn.',
    icon: 'error',
  },
  [ResponseCode.ERROR_INVALID_APPLICATION_YEAR]: {
    title: 'Hết hạn nộp đơn',
    text: `Đã hết hạn nộp đơn! Nếu bạn nghĩ có sai sót, 
      vui lòng liên hệ trang facebook: https://www.facebook.com/Vietseed/ 
      để được hỗ trợ.`,
    icon: 'error',
  },
  [ResponseCode.ERROR_RETRY_LIMIT_EXCEEDED]: {
    title: 'Đã hết giới hạn gửi lại email',
    text: `Bạn đã chọn gửi lại email quá nhiều lần! 
      Vui lòng liên hệ trang facebook: https://www.facebook.com/Vietseed/ 
      để được hỗ trợ.`,
    icon: 'error',
  }, 
  [ResponseCode.ERROR_INVALID_SPONSOR_INVITATION]: {
    title: 'Tài khoản sponsor hết hạn hoặc không hợp lệ',
    text: `Liên hệ VietSeeds để được hỗ trợ gia hạn tài khoản sponsor!`,
    icon: 'error',
  }, 
  [ResponseCode.ERROR_STUDENT_PROFILE_NOT_EXIST]: {
    title: 'Sinh viên không tồn tại',
    text: `Vui lòng kiểm tra lại sinh viên được chọn để tài trợ`,
    icon: 'error',
  }, 
  [ResponseCode.ERROR_OVER_STUDENT_DONATION_AMOUNT]: {
    title: 'Số tiền tài trợ không hợp lệ',
    text: `Vui lòng kiểm tra lại trạng thái tài trợ của sinh viên đã chọn`,
    icon: 'error',
  }, 
  [ResponseCode.ERROR_TRANSACTION_NOT_FOUND]: {
    title: 'Không tìm thấy giao dịch',
    text: `Vui lòng kiểm tra giao dịch đã chọn`,
    icon: 'error',
  }, 
  [ResponseCode.ERROR_NOT_ALLOWED_TO_EDIT_TRANSACTION]: {
    title: 'Không được chỉnh sửa giao dịch',
    text: `Trạng thái hiện tại của giao dịch là không được chỉnh sửa`,
    icon: 'error',
  }, 
}

// receive response.data or error
const handleResponse = async (response, defaultSuccess) => {
  if (get(response, 'result', null) === ResponseCode.SUCCESS && defaultSuccess) {
    return swal(defaultSuccess)
  } else {
    const responseAlert = get(mapErrorToAlert, response.result, null)
    if (responseAlert) {
      return swal(responseAlert)
    } else {
      return swal({
        title: 'Có lỗi xảy ra!',
        text: JSON.stringify(get(response, 'result', response)),
        icon: 'error',
      })
    }
  }
}

export default handleResponse