import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'

import Layout from './components/Layout/index'

import AuthProvider from './connectors/AuthProvider'
import AppRoutes from './AppRoutes'
import RequiredAuthRoute from './RequiredAuthRoute'
import RequiredUnAuthRoute from './RequiredUnAuthRoute'
import NotFound from './pages/NotFound/index'
import Tutorial from './pages/Tutorial/index'

import { getProvinceList, getUniversityList, getNationList } from './redux/actions/generalAction'

const theme = createMuiTheme({
  palette: {
    primary: {
      lighter: '#eef6e9',
      light: '#6fbf73',
      main: '#4caf50',
      littleDark: '#5da62b',
      dark: '#357a38',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f2a18b',
      main: '#ef8a6f',
      dark: '#a7604d',
      contrastText: '#000',
    },
    white: {
      dark: '#464646',
      main: '#fff',
    },
    error: {
      main: '#f0443c',
    }
  }
},)


const App = (props) => {
  const { t } = useTranslation('layout')
  useEffect(() => {
    const { getProvinceListHandler, getUniversityListHandler, getNationListHandler } = props
    getProvinceListHandler()
    getUniversityListHandler()
    getNationListHandler()
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <Tutorial/>
      <Router>
        <Switch>
          {
            AppRoutes.map(route => {
              const { path, exact, requiredAuth, requiredUnAuth, component: Component, ...other } = route

              let RouteComponent = Route
              if (requiredAuth) RouteComponent = RequiredAuthRoute
              if (requiredUnAuth) RouteComponent = RequiredUnAuthRoute

              return (
                <RouteComponent key={path} path={path} exact={exact} {...other}>
                  <Layout>
                    <Component/>
                  </Layout>
                </RouteComponent>
              )
            })
          }
          <Route component={NotFound}/>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProvinceListHandler: getProvinceList,
  getUniversityListHandler: getUniversityList,
  getNationListHandler: getNationList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(App);
