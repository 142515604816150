import { handleActions } from 'redux-actions'

import {
  getStudentList,
  getStudentListSuccess,
  getStudentListFail,

  getStudentInfos,
  getStudentInfosSuccess,
  getStudentInfosFail,
} from '../actions/studentAction'

let defaultState = {
  isGettingStudentList: false,
  studentList: [],
  total: 0,

  isGettingStudentInfos: false,
  studentInfos: {},

  error: null,
}

const studentReducer = handleActions(
  {
    [getStudentList]: (state) => {
      return {
        ...state,
        isGettingStudentList: true,
      }
    },
    [getStudentListSuccess]: (state, { payload: { studentList, total } }) => {
      return {
        ...state,
        studentList,
        total,
        isGettingStudentList: false,
      }
    },
    [getStudentListFail]: (state, { payload: { error } }) => {
      return {
        ...state,
        error,
        isGettingStudentList: false
      }
    },

    [getStudentInfos]: (state) => {
      return {
        ...state,
        isGettingStudentInfos: true,
      }
    },
    [getStudentInfosSuccess]: (state, { payload: { studentInfos } }) => {
      return {
        ...state,
        studentInfos,
        isGettingStudentInfos: false,
      }
    },
    [getStudentInfosFail]: (state, { payload: { error } }) => {
      return {
        ...state,
        error,
        isGettingStudentInfos: false
      }
    },
  },
  defaultState
)

export default studentReducer