import React from 'react'
import { ErrorMessage, useField } from 'formik'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import colors from '../constants/colors'


const RoundSelect = withStyles({
  root: props => ({
    width: props.fullWidth && '100% !important',
    background: '#fff !important',
    borderRadius: '30px !important',
  }),
  select: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    boxSizing: 'border-box',
    padding: '0px 20px',
    background: '#fff',
    borderRadius: 30,
  },
})(Select);

const useStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 15,
    color: '#4A4A4A',
    fontSize: 16,
    '& .MuiInputBase-root': {
      background: '#fff',
      borderRadius: 30,
    },
  },
  error: {
    width: '100%',
    margin: 0,
    paddingLeft: 0,
    color: colors.secondaryMain,
  },
  helperText: {
    margin: 0,
    paddingLeft: 20,
  },
  label: {
    flex: 1,
  },
  inputContainer: {
    width: '70%',
  }
}))

const CustomSelect = (props) => {
  const { field = {}, form, label, choices, required, type, variant, helperText, fullWidth, ...others } = props
  const [, meta,] = useField(field.name);
  const { t: tYup } = useTranslation('yup')

  const classes = useStyles()
  const inputLabel = React.useRef(null);

  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth + (!fullWidth ? 200 : 0));
  }, [fullWidth]);
  return (
    <FormControl 
      required={required} 
      variant="filled" 
      error={meta.touched && meta.error}
      fullWidth={fullWidth}
      className={classes.rootContainer} 
      {...others} 
    >
      <span ref={inputLabel} className={classes.label}>
        {label}
      </span>
      <div className={classes.inputContainer}>
        <RoundSelect
          labelWidth={labelWidth}
          fullWidth={fullWidth}
          disableUnderline
          {...field}
        >
          {
            (choices || []).map(choice => (
              <MenuItem key={`${field.name}-${choice.id}`} value={choice.id}>
                {choice.name}
              </MenuItem>
            ))
          }
        </RoundSelect>
        {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
        <ErrorMessage name={get(field, 'name', '')}
          render={(msg) =>
            <FormHelperText error className={classes.error}>
              {tYup(msg)}
            </FormHelperText>
          }
        />
      </div>
    </FormControl>
  )
}

export default (CustomSelect)


