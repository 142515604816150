import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import AuthProvider from '../../connectors/AuthProvider'
import { navBarItems } from './constants'
import colors from '../../constants/colors'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  active_item: {
    background: colors.primaryLight,
    color: colors.primaryContrastText,
    '& span': {
      fontWeight: 'bold',
    }
  }
});

const SideBar = (props) => {
  const classes = useStyles();
  const { isSidebarOpen, toggleSidebar } = props
  const { t } = useTranslation('layout')
  const location = useLocation()

  return (
    <Drawer anchor={'left'} open={isSidebarOpen} onClose={toggleSidebar(false)}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleSidebar(false)}
        onKeyDown={toggleSidebar(false)}
      >
        <List>
          {
            navBarItems.map(item => {
              const { name, requiredAuth } = item
              const isMatchRoute = (location.pathname === `/${name}`)
              if (requiredAuth && !AuthProvider.checkAuth()) {
                return null
              }
              return (
                <ListItem button
                  component="a"
                  key={name}
                  color="inherit"
                  href={`/${name}`}
                  className={clsx(classes.sidebar_item, isMatchRoute && classes.active_item)}
                >
                  <ListItemText primary={t(`nav-bar-items.${name}`)} />
                </ListItem>
              )
            })
          }
        </List>
        <Divider />
        <List>
          {
            AuthProvider.checkAuth() ?
              <ListItem button color="inherit" onClick={() => AuthProvider.logout()} href={`/login`}>
                <ListItemText primary={t('log-out')} />
              </ListItem>
              :
              <React.Fragment>
                <ListItem button color="inherit" onClick={() => window.location = '/register'}>
                  <ListItemText primary={t('register')} />
                </ListItem>
                <ListItem button color="inherit" onClick={() => window.location='/login'}> 
                  <ListItemText primary={t('log-in')} />
                </ListItem>
              </React.Fragment>
          }
        </List>
      </div>
    </Drawer>
  );
}

export default SideBar
