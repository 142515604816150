import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";
import clsx from 'clsx';

import AuthProvider from '../../connectors/AuthProvider'
import { navBarItems } from './constants'
import colors from '../../constants/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  nav_bar_item: {
    marginRight: 20,
    '&:hover': {
      backgroundColor: colors.primaryMain,
      borderColor: colors.primaryMain,
      boxShadow: 'none',
      color: colors.primaryContrastText,
    },
  },
  active_item: {
    background: colors.primaryLight,
    color: colors.primaryContrastText,
  }
}));

const NavBarItem = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('layout');
  const location = useLocation()

  return (
    <div className={classes.root}>
      {
        navBarItems.map(item => {
          const { name, requiredAuth } = item
          const isMatchRoute = (location.pathname === `/${name}`)
          if (requiredAuth && !AuthProvider.checkAuth()) {
            return null
          }
          return (
            <Button 
              key={name} 
              color="inherit" 
              className={clsx(classes.nav_bar_item, isMatchRoute && classes.active_item)}
              href={`/${name}`}
            >
              {t(`nav-bar-items.${name}`)}
            </Button>
          )
        })
      }
    </div>
  )
}

export default NavBarItem