import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router';

import Pagination from './Pagination'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    }
  }
}))

const ShowMoreButton = withStyles((theme) => ({
  root: {
    fontSize: 12,
    color: '#AFD53D',
    borderRadius: 0,
    width: 380,
    maxWidth: '100%',
    height: 60,
    backgroundColor: '#1D6320',
    '&:hover': {
      backgroundColor: '#9EC42D',
    },
  },
}))(Button);

const PaginationContainer = (props) => {
  const { t } = useTranslation('student_list')
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);
  
  const { total } = props
  const count = Math.ceil(total / 9) // student per page = 9

  const handleChangePage = (newPage) => {
    query.set('page', newPage)
    history.push(`/student-list?${query.toString()}`)
  }

  return (
    <Grid container className={classes.root} justify="space-between" alignItems="center">
      <Grid item xs={12} sm={9}>
        <Pagination count={count} page={page} handleChangePage={handleChangePage}/>
      </Grid>
      <Grid container item xs={12} sm={3} className={classes.buttonContainer}>
        {page < count &&
          <ShowMoreButton onClick={() => handleChangePage(page + 1)}>
            {t('show me more')}
          </ShowMoreButton>
        }
      </Grid>
    </Grid>
  )
} 

export default PaginationContainer