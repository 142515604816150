import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles'
import { find } from 'lodash'

import colors from '../../../constants/colors'

const CustomTextField = withStyles({
  root: props => ({
    width: props.fullWidth && '100% !important',
    marginTop: 10,
    marginBottom: 10,
    textTransform: 'capitalize',
    "& label": {
      lineHeight: 1.5,
      width: '95%'
    },
    "& input:valid + fieldset": {
      borderColor: colors.primaryMain,
      borderWidth: 2
    },
    "& input:invalid + fieldset": {
      borderColor: colors.secondaryMain,
      borderWidth: 2
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important" // override inline-style
    }
  })
})(TextField);

const FilterTextInput = (props) => {
  const { 
    classes, initialValue, field = {}, onChange, onEnter, form, label, type, variant = "outlined", 
    helperText, fullWidth, options = [], ...others 
  } = props

  const [inputValue, setInputValue] = useState(initialValue)
  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])
  const handleInputChange = (e, value, reason) => {
    if (!e) return
    setInputValue(value)
    if (Array.isArray(options)) {
      const found = find(options, { name: value })
      onChange(field.name, found ? found.id : value)
    } else {
      onChange(field.name, value)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onEnter()
    }
  }

  return (
    <Autocomplete
      freeSolo
      id={`filter_${label}`}
      disableClearable
      options={options}
      getOptionLabel={(option) => option.name}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          {...field}
          {...others}
          onKeyDown={handleKeyDown} 
          label={label}
          margin="normal"
          variant={variant}
          fullWidth={fullWidth}
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
    />
  )
}

export default FilterTextInput