import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Section } from 'react-scroll-section';

import ListWrapper from '../ListWrapper'

import { RegionChoices, GenderChoices, } from '../../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
}))

const PersonalInfo = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_profile')
  const { provinceList } = props

  const displayFields = [
    { name: 'id' },
    { name: 'vietseeds_region', options: RegionChoices },
    { name: 'display_name', },
    { name: 'dob', }, // TODO: date field
    // { name: 'ethnic', },
    { name: 'gender', options: GenderChoices, },
    { name: 'province_id', options: provinceList},

  ]

  const { studentInfos } = props

  return (
    <Section id="a-personal-info" className={classes.root}>
      <ListWrapper data={studentInfos} fields={displayFields} t={t} />
    </Section>

  )
}

export default PersonalInfo
