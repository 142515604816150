const GenerationFilterChoices = [
  { id: 11, name: 'F11' },
  { id: 10, name: 'F10' },
  { id: 9, name: 'F9' },
  { id: 8, name: 'F8' },
  { id: 7, name: 'F7' },
  { id: 6, name: 'F6' },
]

export {
  GenerationFilterChoices
}