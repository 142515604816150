import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import ListWrapper from '../ListWrapper'
import TableWrapper from '../TableWrapper'
import CollapsibleWrapper from '../CollapsibleWrapper'

const useStyles = makeStyles((theme) => ({

}))

const BeforeF10Section = (props) => {
  const { studentInfos, universityList, } = props
  const { t } = useTranslation('student_profile')

  return (
    <React.Fragment>
      <ListWrapper
        title={t(`Study result`)}
        data={studentInfos}
        fields={[
          { name: 'university_id', options: universityList, },
          { name: 'study_major', },
          { name: 'extra_data.university.gpa', },
        ]}
        t={t}
      />

      <CollapsibleWrapper title={t(`extra_data.university.achievements`)}>
        <TableWrapper
          data={studentInfos}
          tableName='extra_data.university.achievements'
          fields={[
            { name: 'name', align: 'left', },
          ]}
          t={t}
        />
      </CollapsibleWrapper>

      <CollapsibleWrapper title={t(`extra_data.university.extra_activity`)}>
        <TableWrapper
          data={studentInfos}
          tableName='extra_data.university.extra_activity'
          fields={[
            { name: 'name', align: 'left', },
          ]}
          t={t}
        />
      </CollapsibleWrapper>
    </React.Fragment>
  )
}

export default BeforeF10Section