import axios from 'axios'
import { decode } from 'js-base64';
import { get, set } from 'lodash'

import { backendURL, ResponseCode } from '../constants/constants'
import { getLocalRefreshToken } from '../utils/token';
import authProvider from './AuthProvider';

const RestConnector = () => {
  let instance = axios.create({
    baseURL: backendURL,
    headers: {
      'X-VS-CLIENT-TYPE': '1', // client type for captcha check
      'X-VS-LANGUAGE': localStorage.getItem('i18nextLng') || 'vi',
      'X-VS-ACCESS-TOKEN': window.localStorage.getItem('access_token')
    },
  })

  instance.setToken = (token) => {
    instance.defaults.headers['X-VS-ACCESS-TOKEN'] = token
    window.localStorage.setItem('access_token', token)
  }

  instance.removeToken = () => {
    instance.defaults.headers['X-VS-ACCESS-TOKEN'] = ''
    window.localStorage.removeItem('access_token')
  }

  instance.interceptors.request.use(function (config) {
    // attach email and invitation_code
    let data = get(config, 'data', {})
    set(config, 'data', data)
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  instance.interceptors.response.use(
    function (response) {
      const {code} = response.data
      const refreshToken = getLocalRefreshToken()
      if ((code === 401 || response?.data?.result === ResponseCode.ERROR_UNAUTHORIZED) && refreshToken) {
        console.log('get new token using refresh token', getLocalRefreshToken())
        return authProvider.refreshToken().then(responseToken => {
          const result = get(responseToken, 'data.result', ResponseCode.ERROR_PARAMS)
          if (result === ResponseCode.SUCCESS) {
            const reply = get(responseToken, 'data.reply', {})
            if (reply?.access_token) {
              const access_token = reply?.access_token;
              instance.setToken(access_token);

              const config = response.config
              config.headers['X-VS-ACCESS-TOKEN'] = access_token
              return instance(config)
            }
          }
        })
      }
      return response
    },
    function (err) {
      return Promise.reject(err)
    }
  )

  instance.setLanguageHeader = (language) => {
    instance.defaults.headers.common['HTTP_X_VS_LANGUAGE'] = language;
  }

  return instance
}

let instance = RestConnector()

export default instance
