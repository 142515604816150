import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Route,
  Redirect,
} from "react-router-dom";

const RequiredUnAuthRoute = ({ children, ...rest }) => {
  const { authorized } = rest
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authorized ? (
          <Redirect
            to={{ pathname: "/student-list", }}
          />
        ) : (
            children
          )
      }
    />
  );
}

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(RequiredUnAuthRoute)