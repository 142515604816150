import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import './translations/i18n'
import { sentryDNS } from './constants/constants'

if (sentryDNS) {
  Sentry.init({
    dsn: sentryDNS,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
