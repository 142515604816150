import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { get } from 'lodash'

import mapChoicesToValue from '../../../helpers/mapChoicesToValue'

import PropField from './PropField'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 30,
  },
  title: {
    color: '#4A4A4A',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingLeft: 0,
  },
  item: {
    fontSize: 16,
    color: '#4A4A4A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

const ListWrapper = (props) => {
  const { t, fields, title, data } = props
  const classes = useStyles()

  const propsList = fields.map(field => {
    const { name, options, ...others } = field

    return ({
      name,
      label: t(name),
      value: mapChoicesToValue(options, get(data, name, '')),
      ...others,
    })
  })

  return (
    <List component="nav" aria-label="props list" className={classes.root}>
      {title && 
        <ListItem className={classes.title}>{title}</ListItem>
      }
      {
        propsList.map(prop => {
          const { component: Component = PropField, ...others } = prop
          return (
            <React.Fragment key={prop.name}>
              <ListItem className={classes.item}>
                <Component {...others}/>
              </ListItem>
              <Divider className={classes.divider}/>
            </React.Fragment>
          )
        })
      }
    </List>
  )
}

export default ListWrapper