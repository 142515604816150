import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  container: {
  },
  paper: {
    height: '100%',
    overflow: 'hidden',
    borderRadius: 18,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    objectFit: 'cover',
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#85BE1A',
    textTransform: 'uppercase',
    lineHeight: 2,
  },
  body: {
    flex: 1,
    background: '#F3F7F8',
    padding: 20,
  }
}))

const DonateWay = (props) => {
  const { image, name, children } = props
  const classes = useStyles()
  const { t } = useTranslation('donation_guide')

  return (
    <Grid item xs={12} md={6} className={classes.container}>
      <Paper className={classes.paper} elevation={3}>
        <img src={image} alt='donate-logo' className={classes.image}/>
        <div className={classes.body}>
          {name &&
            <div className={classes.name}>{t(name)}</div>
          }
          {children}
        </div>
      </Paper>
    </Grid>
  )
}

export default DonateWay