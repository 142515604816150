import { get } from 'lodash'
import moment from 'moment'
import { sha256 } from 'js-sha256';
import { decode } from 'js-base64';

import { ResponseCode } from '../constants/constants'
import { getLocalRefreshToken } from '../utils/token';
import restConnector from './RestConnector'

const authProvider = {
  loginSocial:  async({ accessToken, appUserId, linkType }) => {
    const response = await restConnector.post(`/auth/social_login`, {
      link_type: linkType,
      account_info: {
        access_token: accessToken,
        app_user_id: appUserId
      },
    })
    const result = get(response, 'data.result', ResponseCode.ERROR_PARAMS)
    if (result === ResponseCode.SUCCESS) {
      const reply = get(response, 'data.reply', {})
      if (reply?.credentials && reply?.credentials?.access_token) {
        const access_token = reply?.credentials?.access_token;
        const [, payloadToken] = access_token.split('.')
        const payloadStr = decode(payloadToken)
        if (payloadStr) {
          localStorage.setItem('sponsor_profile', payloadStr)
        }
        restConnector.setToken(reply?.credentials?.access_token)
        localStorage.setItem('refresh_token', reply?.credentials?.refresh_token)
      }
      return true
    } else {
      return false
    }
  },
  login:  async({ username, password }) => {
    const encrypted_password = sha256(password)
    const response = await restConnector.post(`/auth/login`, {
      username,
      encrypted_password,
    })
    const result = get(response, 'data.result', ResponseCode.ERROR_PARAMS)
    if (result === ResponseCode.SUCCESS) {
      const reply = get(response, 'data.reply', {})
      if (reply?.credentials && reply?.credentials?.access_token) {
        const access_token = reply?.credentials?.access_token;
        const [, payloadToken] = access_token.split('.')
        const payloadStr = decode(payloadToken)
        if (payloadStr) {
          localStorage.setItem('sponsor_profile', payloadStr)
        }
        restConnector.setToken(reply?.credentials?.access_token)
        localStorage.setItem('refresh_token', reply?.credentials?.refresh_token)
      }
      return true
    } else {
      return false
    }
  },
  refreshToken: () => {
    return restConnector.post(`/auth/refresh_access_token`, {
      refresh_token: getLocalRefreshToken(),
    })
  },
  checkAuth: () => {
    return !!localStorage.getItem('sponsor_profile')
  },
  logout: async () => {
    try {
      const response = await restConnector.post(`/auth/logout`);
      const result = get(response, 'data.result', ResponseCode.ERROR_PARAMS);
      if (result === ResponseCode.SUCCESS) {
        console.log('logout success');
      }
    } catch (e) {
      console.log(e);
    }
    // clear token in local storage
    localStorage.clear()
  },
  register: async (values) => {
    const { data } = await restConnector.post(`/sponsor/register`, values)
    return data
  },
}

export default authProvider
