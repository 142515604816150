import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  container: {
  },
  item: {
    fontSize: 16,
    color: '#4A4A4A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  propName: {
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  propValue: {
    textAlign: 'right',
    lineHeight: 1.5,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.8,
    },
  },
  alignLeft: {
    textAlign: 'left',
  }
}))

const PropsList = (props) => {
  const { propsList } = props
  const classes = useStyles()

  return (
    <List component="nav" aria-label="props list">
      {
        propsList.map(prop => {
          const { noLabel } = prop
          return (
            <React.Fragment key={prop.name}>
              <Divider className={classes.divider}/>
              <ListItem className={classes.item}>
                {!noLabel && <span className={classes.propName}>{prop.label}</span>}
                <span className={clsx(classes.propValue, noLabel && classes.alignLeft)}>{prop.value}</span>
              </ListItem>
            </React.Fragment>
          )
        })
      }
      <Divider className={classes.divider} />
    </List>
  )
}

export default PropsList