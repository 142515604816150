import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { Img } from 'react-image'
import { useTranslation } from 'react-i18next'

import SponsorButton from './SponsorButton'
import ListWrapper from './ListWrapper'

import StudentImagePlaceholder from '../../../static/images/logo.png'
import { RegionChoices, GenerationChoices, ScholarshipPackage } from '../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  body: {
    flex: 1,
    background: '#fff',
    padding: 20,
  },
  image: {
    width: '90%',
    height: '100%',
    borderRadius: 18,
    objectFit: 'contain',
    maxHeight: 520,
  },
  studentName: {
    color: '#85BE1A',
    fontSize: 28,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 20,
  },
  pending: {
    marginTop: 10,
    fontSize: 12,
    color: 'gray',
  }
}))

const SummarySection = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_profile')
  const { provinceList, universityList } = props

  const displayFields = [
    { name: 'display_name', noLabel: true, className: classes.studentName, },
    { name: 'university_id', noLabel: true, options: universityList },
    { name: 'university_year' },
    { name: 'study_major', },
    { name: 'province_id', options: provinceList },
    { name: 'vietseeds_generation', options: GenerationChoices, },
    { name: 'vietseeds_region', options: RegionChoices, },
  ]

  const { studentInfos } = props
  const { profile_picture_url, sponsor_status, has_pending_sponsor } = studentInfos

  return (
    <Grid container item xs={12} className={classes.root}>
      <Divider className={classes.divider}/>
      <Grid container item xs={12} md={5} justify='center'>
        <Img src={[profile_picture_url, StudentImagePlaceholder]} alt='student' className={classes.image} />
      </Grid>
      <Grid container item xs={12} md={7} direction="column">
        <ListWrapper data={studentInfos} fields={displayFields} t={t} />
        <div className={classes.buttonContainer}>
          <SponsorButton 
            label={t('half-scholarship')} 
            amount={ScholarshipPackage.half} 
            href={`/donate?student_id=${studentInfos.id}&amount=half`}
            disabled={sponsor_status && sponsor_status === 2} 
          />
          <SponsorButton 
            label={t('full-scholarship')} 
            amount={ScholarshipPackage.full} 
            href={`/donate?student_id=${studentInfos.id}&amount=full`}
            disabled={sponsor_status && sponsor_status !== 0} 
          />
        </div>
        {has_pending_sponsor &&
          <div className={classes.pending}>
            {t(`pending-for-sponsor-confirmation`)}
          </div>
        }
      </Grid>
    </Grid>
  )
}

export default SummarySection
