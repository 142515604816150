import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { withTranslation } from 'react-i18next'
import { withFormik, Field, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import { cloneDeep, get, set } from 'lodash'

import DonationGuide from '../DonationGuide/index'
import Loading from '../../components/Loading'
import AutoComplete from '../../components/FormAutoComplete'
import TextField from '../../components/FormTextField'
import Checkbox from '../../components/FormCheckbox'
import Select from '../../components/FormSelect'
import BriefStudentProfile from './components/BriefStudentProfile'
import validationSchema from './validationSchema'

import RestConnector from '../../connectors/RestConnector'
import handleResponseAlert from '../../helpers/handleResponseAlert'
import { getStudentInfos } from '../../redux/actions/studentAction'
import { ScholarshipPackage, VietnamCountryCode } from '../../constants/constants'

import countryCodeToFlag from '../../helpers/countryCodeToFlag'

const styles = (theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 50,
      height: 'unset',
      alignItems: 'flex-start',
    },
  },
  container: {
    width: '90%',
    padding: 30,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      // width: 350,
      padding: 10,
    },
    maxWidth: 950,
  },
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 30
  },
  formContainer: {
    background: '#F1F2F2',
    borderRadius: 18,
    padding: 30,
    marginTop: 20,
    marginBottom: 20,
  },
  error: {
    fontSize: 10,
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  submitButton: {
    background: '#AFD53D',
    color: '#fff',
    borderRadius: 10,
    height: 50,
    marginTop: 20,
    fontSize: 16,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  }
}))

class DonateForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
    }
  }

  componentWillMount() {
    const { getStudentInfosHandler, location } = this.props
    const query = new URLSearchParams(location.search)
    const studentId = parseInt(query.get('student_id'))

    getStudentInfosHandler({ id: studentId })
  }

  handleClose = () => {
    this.setState({
      openDialog: false,
    })
  }

  handleClickOpen = () => {
    this.setState({
      openDialog: true,
    })
  }

  render() {
    const {
      classes, t, handleSubmit, location, studentInfos,
      nationList, provinceList, isGettingStudentInfos, values
    } = this.props
    const { openDialog } = this.state

    const query = new URLSearchParams(location.search);
    const amount = query.get('amount') || 'full';

    const donateFormFields = [
      {
        name: 'name', component: TextField,
        required: true, fullWidth: true
      },
      {
        name: 'phone', component: TextField,
        required: true, fullWidth: true
      },
      {
        name: 'email', component: TextField,
        required: true, fullWidth: true
      },
      {
        name: 'preferred_contact_method', component: Checkbox,
        choices: [
          { id: 1, name: t('phone') },
          { id: 2, name: t('email') },
        ],
        required: true, fullWidth: true,
      },
      {
        name: 'country', component: AutoComplete,
        required: true, fullWidth: true,
        options: nationList,
        getOptionLabel: (option) => option.name,
        renderOption: (option) => (
          <React.Fragment>
            <span>{countryCodeToFlag(option.code)}</span>
            {` ${option.name}`}
          </React.Fragment>
        ),
      },
      {
        name: 'province', component: (get(values, 'country.id', '') === VietnamCountryCode) ? AutoComplete : null, // selected Vietnam
        required: true, fullWidth: true,
        options: provinceList,
        getOptionLabel: (option) => option.name,
        renderOption: (option) => (
          <React.Fragment>
            <span>{option.name}</span>
          </React.Fragment>
        ),
      },
      {
        name: 'donation_type', component: Select,
        choices: [
          { id: 1, name: t('Vietcombank Transfer') },
          { id: 2, name: t('Paypal') },
          { id: 3, name: t('Wells Fargo') },
          { id: 4, name: t('Checks') },
        ],
        required: true, fullWidth: true,
        helperText: <span onClick={this.handleClickOpen} className={classes.link} >{t('donation_guide')}</span>
      },
      {
        name: 'note', component: TextField,
        fullWidth: true,
        multiline: true,
        rows: 5,
      },
    ]

    if (isGettingStudentInfos) {
      return (
        <Loading isLoading={isGettingStudentInfos} />
      )
    }

    return (
      <div className={classes.root}>
        <Form onSubmit={handleSubmit} className={classes.container}>
          <div className={classes.header}>{`${t(amount)} - $${get(ScholarshipPackage, amount, 1500)}`}</div>
          <BriefStudentProfile studentInfos={studentInfos}/>

          <Paper elevation={0} className={classes.formContainer}>
            {
              donateFormFields.map(field => {
                const { name, component } = field
                if (!component) return null
                return (
                  <Field
                    key={name}
                    name={name}
                    label={t(name)}
                    component={component}
                    {...field}
                  />
                )
              })
            }
          </Paper>

          <Button color="primary" type="submit" variant="contained" fullWidth className={classes.submitButton}>
            {t('confirm')}
          </Button>
        </Form>

        <Dialog
          fullWidth
          maxWidth="xl"
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <DonationGuide/>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.handleClose} color="primary">
              {t(`Close`)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const DonateFormWithFormik = withFormik({
  mapPropsToValues: (props) => {
    const sponsorProfile = JSON.parse(localStorage.getItem('sponsor_profile'))

    return ({
      invitation_code: get(sponsorProfile, 'invitation_code', ''),
      name: get(sponsorProfile, 'display_name', '') || get(sponsorProfile, 'extra_data.name', ''),
      phone: get(sponsorProfile, 'phone', ''),
      email: get(sponsorProfile, 'email', ''),
      preferred_contact_method: get(sponsorProfile, 'extra_data.preferred_contact_method', ''),
      country: get(sponsorProfile, 'extra_data.country', ''),
      province: get(sponsorProfile, 'extra_data.province', ''),
      donation_type: get(sponsorProfile, 'extra_data.donation_type', ''),
      // note: get(sponsorProfile, 'extra_data.note', ''),
      note: '',
    });
  },

  validationSchema: validationSchema,

  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    setSubmitting(true)
    const { t, location } = props

    const query = new URLSearchParams(location.search)
    const student_id = parseInt(query.get('student_id'))
    const amount = query.get('amount') || 'full';

    const { invitation_code, email, ...extra_data } = values

    let store_extra_data = cloneDeep(extra_data)
    extra_data.country_id = get(extra_data, 'country.id', '')
    extra_data.province_id = get(extra_data, 'province.id', '')
    if (extra_data.country_id !== VietnamCountryCode) {
      delete extra_data.province_id
      delete store_extra_data.province
    }
    delete extra_data.country
    delete extra_data.province

    const response = await RestConnector.post('/sponsor/create_student_donation', {
      email,
      invitation_code,
      student_id,
      transaction_amount: (amount === 'full') ? 2 : 1,
      extra_data
    })

    const sponsorProfile = JSON.parse(localStorage.getItem('sponsor_profile'))
    localStorage.setItem('sponsor_profile', JSON.stringify({
      ...sponsorProfile,
      ...values,
      extra_data: store_extra_data,
    }))

    const responseData = get(response, 'data')
    handleResponseAlert(responseData, {
      title: t('sponsor successfully'),
      text: t('sponsored successfully message'),
      icon: 'success'
    })

    setSubmitting(false)
  }
})(withStyles(styles)(DonateForm))

const mapStateToProps = ({ student, general }) => {
  return {
    studentInfos: student.studentInfos,
    isGettingStudentInfos: student.isGettingStudentInfos,
    nationList: general.nationList,
    provinceList: general.provinceList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getStudentInfosHandler: getStudentInfos,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('donate_form')(withRouter(DonateFormWithFormik)))
