import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import LogoHorizontal from '../../static/images/logo-hor.png'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50
  },
}));

const Logo = (props) => {
  const classes = useStyles()

  return (
    <a href='/student-list'>
      <img src={LogoHorizontal} className={classes.logo} alt='logo-horizontal'/>
    </a>
  )
}

export default Logo