import { createActions } from 'redux-actions'


export const {
  getStudentList,
  getStudentListSuccess,
  getStudentListFail,

  getStudentInfos,
  getStudentInfosSuccess,
  getStudentInfosFail,
} = createActions(
  'GET_STUDENT_LIST',
  'GET_STUDENT_LIST_SUCCESS',
  'GET_STUDENT_LIST_FAIL',

  'GET_STUDENT_INFOS',
  'GET_STUDENT_INFOS_SUCCESS',
  'GET_STUDENT_INFOS_FAIL',
)