const studentPersonalSharingFieldsByGeneration = {
  10: [
    {
      question_id: `personal_sharing.f10.question_0`,
      question: `Tại sao việc đi học đại học lại quan trọng với em? [Tối thiểu 100 từ]`,
      name: 'extra_data.essay.essay[0].answer',
    },
    {
      question_id: `personal_sharing.f10.question_1`,
      question: `Vui lòng chia sẻ với chúng tôi về bản thân em? 
                Với em điều gì là quan trọng nhất trong cuộc sống? 
                Tại sao? [Tối thiểu 150 từ]`,
      name: 'extra_data.essay.essay[1].answer',
    },
    {
      question_id: `personal_sharing.f10.question_2`,
      question: `Nếu có thể thay đổi bất cứ điều gì trong cuộc sống của mình, 
              em muốn thay đổi điểu gì nhất? Em cần những sự hỗ trợ, 
              giúp đỡ nào để có thể thực hiện sự thay đổi đó? [Tối thiểu 150 từ]`,
      name: 'extra_data.essay.essay[3].answer',
    },
    {
      question_id: `personal_sharing.f10.question_3`,
      question: `Nếu có điều gì chưa được nhắc đến trong hồ sơ mà 
                em muốn Hội Đồng Tuyển Chọn biết để cân nhắc hồ sơ của em, 
                em vui lòng chia sẻ ở đây. (Không bắt buộc)`,
      name: 'extra_data.essay.essay[2].answer',
      defaultAnswer: `Các câu hỏi ở trên đã giúp em chia sẻ hết rồi ạ. Em cảm ơn các anh chị ạ.`
    },
  ],
  11: [
    {
      question_id: `personal_sharing.f11.question_0`,
      question: `Nếu chọn 3 từ để nói về em, 3 từ đó là gì? Hãy giải thích rõ hơn về 3 từ đó. [Tối thiểu 100 từ]`,
      name: 'extra_data.essay.essay[0].answer',
    },
    {
      question_id: `personal_sharing.f11.question_1`,
      question: `Ước mơ của em sau khi ra trường là gì? Ngành học em đang chọn có liên quan thế nào đến ước mơ đó? [Tối thiểu 100 từ]`,
      name: 'extra_data.essay.essay[1].answer'
    },
    {
      question_id: `personal_sharing.f11.question_2`,
      question: `Ai là người truyền cảm hứng cho em nhiều nhất? Tại sao? [Tối thiểu 100 từ]`,
      name: 'extra_data.essay.essay[4].answer'
    }
  ]
}


const essayFieldsByGeneration = {
  11: [
    {
      question_id: `essays.f11.question_0`,
      question: `Hãy chia sẻ về điểm mạnh và điểm yếu của bản thân em với những ví dụ cụ thể
                liên quan đến các điểm mạnh và điểm yếu đó. [Tối thiểu 150 từ]`,
      name: 'extra_data.essay.essay[5].answer',
    },
    {
      question_id: `essays.f11.question_1`,
      question: `Viết về một quyển sách mà em thích nhất. [Tối thiểu 150 từ]`,
      name: 'extra_data.essay.essay[6].answer',
    },
    {
      question_id: `essays.f11.question_2`,
      question: `Hãy chia sẻ về giai đoạn khó khăn nhất trong cuộc sống của em
                và làm cách nào để em vượt qua được giai đoạn đó. [Tối thiểu 150 từ]`,
      name: 'extra_data.essay.essay[7].answer',
    },
  ]
}

export {
  studentPersonalSharingFieldsByGeneration,
  essayFieldsByGeneration
}