import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 16,
    color: '#4A4A4A',
  },
  bold: {
    fontWeight: 'bold',
  }
}))

const PlainText = (props) => {
  const { value, bold, className, ...others } = props
  const classes = useStyles()

  return (
    <div className={clsx(classes.text, bold && classes.bold, className)} {...others}>
      {value}
    </div>
  )
}

export default PlainText
