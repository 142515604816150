import React from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'


const useStyles = makeStyles(theme => ({
  item: {
    fontSize: 16,
    color: '#4A4A4A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
    lineHeight: 1.8
  },
  propName: {
    fontWeight: 'bold',
    minWidth: '30%',
  },
  propValue: {
    textAlign: 'right',
    lineHeight: 1.8,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.8,
    },
    wordBreak: 'break-all'
  },
  noNamePropValue: {
    textAlign: 'left',
    lineHeight: 1.8,
    fontSize: 16,
  },
  image: {
    width: 190,
    margin: 'auto',
  }
}))

const QRWalletInfo = (props) => {
  const { address, image, propsList } = props
  const classes = useStyles()
  const { t } = useTranslation('donation_guide')
  return (
    <List component="nav" aria-label="props list">
      <Divider/>
      <ListItem className={classes.item}>
        <span className={classes.propName}>{t(`Now, you can donate for VietSeeds by crypto-currency.`)}</span>
      </ListItem>
      <ListItem className={classes.item}>
        <span className={classes.propName}>{t(`Address`)}</span>
        <span className={classes.propValue}>{address}</span>
      </ListItem>
      {propsList &&
        propsList.map(prop => {
          return (
            <React.Fragment key={`${prop.name}-${prop.value}`}>
              <Divider className={classes.divider} />
              <ListItem className={classes.item}>
                {prop.name && <span className={classes.propName}>{t(prop.name)}</span>}
                <span className={clsx(prop.name ? classes.propValue : classes.noNamePropValue)}>{t(prop.value)}</span>
              </ListItem>
            </React.Fragment>
          )
        })
      }

      <Divider/>
      <ListItem className={classes.item}>
        <img src={image} alt='wallet-qr' className={classes.image} />
      </ListItem>
    </List>
  )
}

export default QRWalletInfo
