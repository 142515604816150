import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { Section } from 'react-scroll-section';

import EssayField from '../EssayField'
import { essayFieldsByGeneration} from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
}))

const PersonalSharing = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('student_profile')

  const { studentInfos } = props
  const generation = get(studentInfos, 'vietseeds_generation', 0)
  const essayFields = get(essayFieldsByGeneration, generation, [])
  if (essayFields.length === 0) {
    return null
  }

  return (
    <Section id="d-essays" className={classes.root}>
      {essayFields.map((field, index) => {
        const { question_id, name, defaultAnswer = '' } = field
        return (
          <EssayField
            key={name}
            question={`${index + 1}. ${t(question_id)}`}
            answer={get(studentInfos, name, defaultAnswer)}
          />
        )
      })
      }
    </Section>
  )
}

export default PersonalSharing
