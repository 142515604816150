import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
import { withFormik, Field, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import AuthProvider from '../../connectors/AuthProvider'
import TextField from '../../components/FormTextField'
import Checkbox from '../../components/FormCheckbox'
import AutoComplete from '../../components/FormAutoComplete'
import validationSchema from './validationSchema'
import { VietnamCountryCode } from '../../constants/constants'

import countryCodeToFlag from '../../helpers/countryCodeToFlag'
import handleResponseAlert from '../../helpers/handleResponseAlert'

const styles = (theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 50,
      height: 'unset',
      alignItems: 'flex-start',
    },
  },
  container: {
    width: '90%',
    padding: 30,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      // width: 350,
      padding: 10,
    },
    maxWidth: 950,
  },
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 30
  },
  formContainer: {
    background: '#F1F2F2',
    borderRadius: 18,
    padding: 30,
    marginTop: 20,
    marginBottom: 20,
  },
  error: {
    fontSize: 10,
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  submitButton: {
    background: '#AFD53D',
    color: '#fff',
    borderRadius: 10,
    height: 50,
    marginTop: 20,
    fontSize: 16,
  }
}))

class Register extends React.Component {
  render() {
    const { classes, t, handleSubmit, provinceList, nationList, values } = this.props

    const registerFields = [
      {
        name: 'name', component: TextField,
        required: true, fullWidth: true,
      },
      {
        name: 'phone', component: TextField,
        required: true, fullWidth: true,
      },
      {
        name: 'email', component: TextField,
        required: true, fullWidth: true,
      },
      {
        name: 'preferred_contact_method', component: Checkbox,
        choices: [
          { id: 1, name: t('phone') },
          { id: 2, name: t('email') },
        ],
        required: true, fullWidth: true,
      },
      {
        name: 'country', component: AutoComplete,
        required: true, fullWidth: true,
        options: nationList,
        getOptionLabel: (option) => option.name,
        renderOption: (option) => (
          <React.Fragment>
            <span>{countryCodeToFlag(option.code)}</span>
            {` ${option.name}`}
          </React.Fragment>
        ),
      },
      {
        name: 'province', component: (get(values, 'country.id', '') === VietnamCountryCode) ? AutoComplete : null, // selected Vietnam
        required: true, fullWidth: true,
        options: provinceList,
        getOptionLabel: (option) => option.name,
        renderOption: (option) => (
          <React.Fragment>
            <span>{option.name}</span>
          </React.Fragment>
        ),
      },
      {
        name: 'note', component: TextField,
        fullWidth: true,
        multiline: true,
        rows: 5,
      },
    ]

    return (
      <div className={classes.root}>
        <Form onSubmit={handleSubmit} className={classes.container}>
          <div className={classes.header}>{t('register')}</div>

          <Paper elevation={0} className={classes.formContainer}>

            {
              registerFields.map(field => {
                const { name, component } = field
                if (!component) return null
                return (
                  <Field
                    key={name}
                    name={name}
                    label={t(name)}
                    component={component}
                    {...field}
                  />
                )
              })
            }
          </Paper>

          <Button color="primary" type="submit" variant="contained" fullWidth className={classes.submitButton}>
            {t('register')}
          </Button>
        </Form>
      </div>
    )
  }
}

const RegisterWithFormik = withFormik({
  mapPropsToValues: (props) => {
    return ({
        name: '',
        phone: '',
        email: '',
        preferred_contact_method: '',
        country: '',
        province: '',
        note: '',
    });
  },

  validationSchema: validationSchema,

  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    setSubmitting(true)
    const { t } = props

    const { name, phone, email, ...extra_data } = values

    extra_data.country_id = get(extra_data, 'country.id', '')
    extra_data.province_id = get(extra_data, 'province.id', '')
    if (extra_data.country_id !== VietnamCountryCode) {
      delete extra_data.province_id
    }
    delete extra_data.country
    delete extra_data.province

    const registerResponse = await AuthProvider.register({
      name, 
      phone, 
      email,
      extra_data
    })

    handleResponseAlert(registerResponse, {
      title: t('register success'),
      text: t("registered successfully"),
      icon: "success"
    })

    setSubmitting(false)
  }
})(withStyles(styles)(Register))

const mapStateToProps = ({ student, general }) => {
  return {
    nationList: general.nationList,
    provinceList: general.provinceList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('register')(withRouter(RegisterWithFormik)))