import { call, put, takeLeading } from 'redux-saga/effects'
import get from 'lodash/get'

import {
  getProvinceList,
  getProvinceListSuccess,
  getProvinceListFail,

  getUniversityList,
  getUniversityListSuccess,
  getUniversityListFail,

  getNationList,
  getNationListSuccess,
  getNationListFail,
} from '../actions/generalAction'

import restConnector from '../../connectors/RestConnector'

function* getProvinceListSaga() {
  try {
    const response = yield call(restConnector.get, `/general/get_province_list`)
    const provinces = get(response, 'data.reply.provinces', [])

    yield put(getProvinceListSuccess(provinces))
  } catch (error) {
    yield put(getProvinceListFail(error))
  }
}

function* getUniversityListSaga() {
  try {
    const response = yield call(restConnector.get, `/general/get_university_list`)
    const universityList = get(response, 'data.reply.universities', [])

    yield put(getUniversityListSuccess(universityList))
  } catch (error) {
    yield put(getUniversityListFail(error))
  }
}

function* getNationListSaga() {
  try {
    const response = yield call(restConnector.get, `/general/get_nation_list`)
    const nations = get(response, 'data.reply.nations', [])

    yield put(getNationListSuccess(nations))
  } catch (error) {
    yield put(getNationListFail(error))
  }
}


function* getProvinceListWatcher() {
  yield takeLeading(getProvinceList, getProvinceListSaga)
}


function* getUniversityListWatcher() {
  yield takeLeading(getUniversityList, getUniversityListSaga)
}

function* getNationListWatcher() {
  yield takeLeading(getNationList, getNationListSaga)
}


export {
  getProvinceListWatcher,
  getUniversityListWatcher,
  getNationListWatcher,
}