import React from 'react'
import { ErrorMessage, useField } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import colors from '../constants/colors'

const RoundInput = withStyles({
  root: props => ({
    width: props.fullWidth && '100% !important',
  }),
  input: {
    height: 50,
    boxSizing: 'border-box',
    paddingLeft: 20,
    paddingRight: 20,
    background: '#fff',
    borderRadius: 30,
  },
  inputMultiline: {
    height: 'unset',
    padding: 20,
    background: '#fff',
    borderRadius: 30,
  },
  error: {
    "& input": {
      border: `solid 1px ${colors.secondaryMain}`,
    },
  }
})(Input);

const styles = (theme) => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15, 
    marginBottom: 15,
    color: '#4A4A4A',
    fontSize: 16,
  },
  error: {
    margin: 0,
    paddingLeft: 20,
    color: colors.secondaryMain,
  },
  helperText: {
    margin: 0,
    paddingLeft: 20,
  },
  label: {
    flex: 1,
  },
  inputContainer: {
    width: '70%',
  }
})

const CustomInput = (props) => {
  const { classes, field = {}, form, label, required, type, variant, helperText, fullWidth, rows, multiline, ...others } = props
  const [, meta, ] = useField(field.name);
  const { t: tYup } = useTranslation('yup')

  return (
    <FormControl 
      fullWidth={fullWidth} 
      className={classes.rootContainer} 
      error={meta.touched && meta.error}
      {...others}
    >
      <span 
        htmlFor={get(field, 'name', '')}
        className={classes.label}
      >
        {label}
      </span>
      <div className={classes.inputContainer}>
        <RoundInput 
          id={get(field, 'name', '')} 
          type={type}
          variant={'filled'}
          disableUnderline
          rows={rows}
          multiline={multiline}
          fullWidth
          required={required}
          {...field} 
        />
        {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
        <ErrorMessage name={get(field, 'name', '')}
          render={(msg) =>
            <FormHelperText error className={classes.error}>
              {tYup(msg)}
            </FormHelperText>
          }
        />
      </div>
    </FormControl>
  )
}

export default withStyles(styles)(CustomInput)
