import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const styles = (theme => ({

}))

class MyStudentList extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        My student List
      </div>
    )
  }
}


const mapStateToProps = ({ general }) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyStudentList))