import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  container: {
  },
  item: {
    fontSize: 16,
    color: '#4A4A4A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  noNamePropValue: {
    textAlign: 'left',
    lineHeight: 1.8,
    fontSize: 16,
  },
  propName: {
    fontWeight: 'bold',
  },
  propValue: {
    textAlign: 'right',
    lineHeight: 1.8,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.8,
    },
  },
}))

const PropsList = (props) => {
  const { propsList } = props
  const classes = useStyles()
  const { t } = useTranslation('donation_guide')
  return (
    <List component="nav" aria-label="props list">
      {
        propsList.map(prop => {
          return (
            <React.Fragment key={`${prop.name}-${prop.value}`}>
              <Divider className={classes.divider}/>
              <ListItem className={classes.item}>
                {prop.name && <span className={classes.propName}>{t(prop.name)}</span>}
                <span className={clsx(prop.name ? classes.propValue : classes.noNamePropValue)}>{t(prop.value)}</span>
              </ListItem>
            </React.Fragment>
          )
        })
      }
    </List>
  )
}

export default PropsList