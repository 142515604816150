import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
  },
  title: {
    background: '#E3E3E3',
    color: '#4A4A4A',
    fontWeight: 'bold',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
}))

const CollapsibleWrapper = (props) => {
  const { title, children } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.title} onClick={() => setOpen(!open)}>
        <div className={classes.titleText}>
          {title}
        </div>
        <IconButton aria-label="expand row" size="medium" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      <Collapse in={open}>
        {children}
      </Collapse>
    </div>
  )
}

export default CollapsibleWrapper