import * as Yup from 'yup';

const REQUIRE_MESSAGE = 'required field'

Yup.addMethod(Yup.string, 'length', function (args) {
  const { message = 'length limit exceeded', limit = 1024 } = args
  return this.test('string-length-limit', message, function (value) {
    const { path, createError } = this;
    return !value || value.length <= limit || createError({ path, message });
  });
})

const donateFormSchema = Yup.object().shape({
  email: Yup.string().email('invalid email').required(REQUIRE_MESSAGE),
  name: Yup.string().required(REQUIRE_MESSAGE),
  phone: Yup.string().required(REQUIRE_MESSAGE),
  preferred_contact_method: Yup.string().required(REQUIRE_MESSAGE),
  donation_type: Yup.string().required(REQUIRE_MESSAGE),
  country: Yup.object().shape({
    id: Yup.number().required(REQUIRE_MESSAGE)
  }),
  province: Yup.object().shape({
    id: Yup.number() // TODO: require based on country_id
  }),
  note: Yup.string(),
});

export default donateFormSchema