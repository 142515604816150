const backendURL = process.env.REACT_APP_BACKEND_URL
const sentryDNS = process.env.REACT_APP_SENTRY_DNS

const defaultLanguage = "vi"
const VietnamCountryCode = 239

const GenerationChoices = [
  { id: 1, name: 'F1' },
  { id: 2, name: 'F2' },
  { id: 3, name: 'F3' },
  { id: 4, name: 'F4' },
  { id: 5, name: 'F5' },
  { id: 6, name: 'F6' },
  { id: 7, name: 'F7' },
  { id: 8, name: 'F8' },
  { id: 9, name: 'F9' },
  { id: 10, name: 'F10' },
  { id: 11, name: 'F11' },
]

const RegionChoices = [
  { id: 1, name: 'Hồ Chí Minh', },
  { id: 2, name: 'Hà Nội', },
  { id: 3, name: 'Huế', },
]


const ScholarshipPackage = {
  half: 750,
  full: 1500
}

const GenderChoices = [
  { id: 1, name: 'Nam', },
  { id: 2, name: 'Nữ', },
  { id: 3, name: 'Khác', }
]

const ResponseCode = {
  SUCCESS: 'success',

  ERROR_PARAMS: 'error_params',
  ERROR_HEADER: 'error_header',
  ERROR_FORBIDDEN: 'error_forbidden',
  ERROR_CAPTCHA: 'error_captcha',

  // Authentication error
  ERROR_INVALID_LOGIN_CREDENTIAL: 'error_invalid_login_credential',
  ERROR_INVALID_TOKEN: 'error_invalid_token',

  ERROR_INVALID_FILE_FORMAT: 'error_invalid_file_format',
  ERROR_IMAGE_UPLOAD_FAILED: 'error_image_upload_failed',
  ERROR_FILE_UPLOAD_FAILED: 'error_file_upload_failed',

  ERROR_DUPLICATED_APPLICATION: 'error_duplicated_application',
  ERROR_INVALID_APPLICATION_CREDENTIAL: 'error_invalid_application_credential',
  ERROR_NOT_ALLOWED_TO_EDIT_APPLICATION: 'error_not_allowed_to_edit_application',
  ERROR_NOT_ALLOWED_TO_REGISTER_APPLICATION: 'error_not_allowed_to_register_application',
  ERROR_SUBMIT_APPLICATION_FAILED: 'error_submit_application_failed',
  ERROR_INVALID_APPLICATION_YEAR: 'error_invalid_application_year',
  ERROR_RETRY_LIMIT_EXCEEDED: 'error_limit_exceeded',

  ERROR_INVALID_SPONSOR_INVITATION: 'error_invalid_sponsor_invitation',
  ERROR_EXPIRED_SPONSOR_INVITATION: 'error_expired_sponsor_invitation',
  ERROR_APPLICATION_NOT_EXIST: 'error_application_not_exist',

  ERROR_UNIVERSITY_NOT_FOUND: 'error_university_not_found',
  ERROR_STUDENT_PROFILE_NOT_EXIST: 'error_student_profile_not_exist',
  ERROR_OVER_STUDENT_DONATION_AMOUNT: 'error_over_donation_amount',
  ERROR_TRANSACTION_NOT_FOUND: 'error_transaction_not_found',
  ERROR_NOT_ALLOWED_TO_EDIT_TRANSACTION: 'error_not_allowed_to_edit_transaction',
}

export {
  backendURL,
  sentryDNS,
  defaultLanguage,
  VietnamCountryCode,
  ResponseCode,
  ScholarshipPackage,
  GenerationChoices,
  RegionChoices,
  GenderChoices,
}