import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withFormik, Field, Form } from 'formik'
import { get } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Link from '../../components/Link'
import TextField from '../../components/TextField'
import AuthProvider from '../../connectors/AuthProvider'

import { refreshAuthState } from '../../redux/actions/accountAction'
import validationSchema from './validationSchema'
/*global FB*/

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 50,
      height: 'unset',
      alignItems: 'flex-start',
    }
  },
  container: {
    width: 400,
    padding: 30,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: 10,
    }
  },
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20
  },
  error: {
    fontSize: 10,
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  noAccountContainer: {
    textAlign: 'center',
    marginTop: 30,
  },
  loginSocial: {
    marginTop: 12,
    textAlign: 'center'
  },
  loginSocialLabel: {
    marginBottom: 12,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  facebookIcon: {
    display: 'inline-block',
    minWidth: '24px',
    maxWidth: '24px',
    minHeight: '24px',
    maxHeight: '24px',
    background: 'url(/media/icons/facebook-icon.svg)',
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  loginSocialIconLabel: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 600
  },
  buttonLoginSocial: {
    display: 'flex',
    alignItems: 'center',

    padding: '4px 12px 4px 6px',
    cursor: 'pointer',
    margin: 'auto',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    border: '1px',
    backgroundColor: '#0084ff',
    borderColor: '#0084ff',

    '&:hover': {
      backgroundColor: '#007bed',
      borderColor: '#007bed',
    }
  },
  prefix: {
    color: theme.palette.white.dark,
  },
  createAccount: {
    marginLeft: 10,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.primary.dark
  }
}))

const Login = (props) => {
  const [show_invitation_code, toggleShowInvitationCode] = useState(false)
  const { handleSubmit, errors, setFieldValue, values, setSubmitting, setFieldError } = props
  const classes = useStyles()
  const { t } = useTranslation('login')

  const loginError = get(errors, 'general', null)

  useEffect(() => {
    if (values.username) {
      setFieldValue('username', values.username.trim())
    }
    if (values.password) {
      setFieldValue('password', values.password.trim())
    }
  }, [values.username, values.password])

  const handleSubmitLoginSocial = async (values) => {
    setSubmitting(true)

    const { accessToken, appUserId, linkType } = values
    // Call API to check valid username and password
    const loginResponse = await AuthProvider.loginSocial({ accessToken, appUserId, linkType })
    const { location, history, refreshAuthStateHandler } = props

    let { from } = location.state || { from: { pathname: "/student-list" } };

    if (loginResponse) {
      refreshAuthStateHandler()
      history.replace(from);
    } else {
      setFieldError('general', 'wrong username or password')
    }

    setSubmitting(false)
  }

  const loginByFacebook = () => {
    if (FB?.login) {
      FB.login(function (response) {
        if (response) {
          const {
            authResponse,
            status
          } = response;

          if (status === "connected" && authResponse) {
            const {
              accessToken,
              userID,
              expiresIn,
              signedRequest,
              graphDomain,
              data_access_expiration_time
            } = authResponse;
            handleSubmitLoginSocial({
              accessToken: accessToken,
              appUserId: userID,
              linkType: 1
            });
          } else {
            setFieldError('general', 'error login facebook')
          }
        }
      }, { scope: 'public_profile,email' });
    }
  }
  return (
    <div className={classes.root}>
      <Paper elevation={5} className={classes.container}>
        <Form onSubmit={handleSubmit}>
          <div className={classes.header}>{t('login')}</div>
          {loginError && <div className={classes.error}>{t(loginError)}</div>}
          <Field name={`username`} label={t('Username')} required fullWidth component={TextField}
                 InputProps={{
                   startAdornment:
                     <InputAdornment position="start">
                       <IconButton
                         aria-label="username"
                       >
                         <AccountCircle/>
                       </IconButton>
                     </InputAdornment>
                 }}
          />
          <Field name={`password`} label={t('Password')} required fullWidth component={TextField}
                 type={show_invitation_code ? 'text' : 'password'}
                 InputProps={{
                   startAdornment:
                     <InputAdornment position="start">
                       <IconButton
                         aria-label="password"
                       >
                         <LockIcon/>
                       </IconButton>
                     </InputAdornment>,
                   endAdornment:
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="toggle password visibility"
                         onClick={() => toggleShowInvitationCode(!show_invitation_code)}
                       >
                         {show_invitation_code ? <Visibility/> : <VisibilityOff/>}
                       </IconButton>
                     </InputAdornment>
                 }}
          />
          <Button color="primary" type="submit" variant="contained" fullWidth style={{ marginTop: 20 }}>
            {t('login')}
          </Button>
        </Form>
        <div className={classes.loginSocial}>
          <div className={classes.loginSocialLabel}>
            {t('or')}
          </div>
          <button className={classes.buttonLoginSocial} onClick={loginByFacebook}>
            <i className={classes.facebookIcon}/>
            <span className={classes.loginSocialIconLabel}>
                {t('login_by_facebook')}
              </span>
          </button>
        </div>

        <div className={classes.noAccountContainer}>
          <span className={classes.prefix}>{t('not having an account')}?</span>
          <Link className={classes.createAccount} to='/register'>
            {t('create account')}
          </Link>
        </div>

        <div style={{ marginTop: 20, lineHeight: 1.25 }}>
          {t(`technical support contact`)}
          <a href="mailto:vs.sponsorship@vietseeds.org">vs.sponsorship@vietseeds.org</a>
        </div>
      </Paper>
    </div>
  )
}

const LoginWithFormik = withFormik({
  initialValues: {
    username: '',
    password: '',
  },

  validationSchema: validationSchema,

  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    setSubmitting(true)

    const { username, password } = values
    // Call API to check valid username and password
    const loginResponse = await AuthProvider.login({ username, password })
    const { location, history, refreshAuthStateHandler } = props

    let { from } = location.state || { from: { pathname: "/student-list" } };

    if (loginResponse) {
      refreshAuthStateHandler()
      history.replace(from);
    } else {
      setFieldError('general', 'wrong username or password')
    }

    setSubmitting(false)
  }
})(Login)

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refreshAuthStateHandler: refreshAuthState,
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginWithFormik))
