import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import FilterIcon from '@material-ui/icons/FilterList'
import { useHistory } from 'react-router-dom'
import { find } from 'lodash'

import { RegionChoices } from '../../../constants/constants'
import { GenerationFilterChoices } from '../constants'
import FilterTextInput from './FilterTextInput'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    marginBottom: 30,
  },
  divider: {
    width: '100%',
  },
  verticalDivider: {
    height: 22, 
    marginLeft: 20, 
    marginRight: 20,
  },
  title: {
    color: '#4A4A4A',
    fontSize: 12,
    textTransform: 'uppercase',
  }
}))

const FilterButton = withStyles((theme) => ({
  root: {
    fontSize: 12,
    color: '#1D6320',
    borderRadius: 17,
    width: 180,
    maxWidth: '100%',
    height: 34,
    backgroundColor: '#AFD53D',
    '&:hover': {
      backgroundColor: '#9EC42D',
    },
  },
}))(Button);

const Filter = (props) => {
  const { t } = useTranslation('student_list')
  const classes = useStyles()
  const history = useHistory()

  const { filter = {}, query, provinceList } = props
  const [newFilters, setNewFilters] = useState(filter)

  const handleClickFilter = () => {
    query.set('page', 1)
    query.set('filter', JSON.stringify(newFilters))
    history.push(`/student-list?${query.toString()}`)
  }

  const handleChangeFilter = (name, value) => {
    setNewFilters({
      ...newFilters,
      [name]: value.name || value,
    })
  }

  const getNameOfIdFromOptions = (value, options) => {
    if (Array.isArray(options)) {
      const found = find(options, { id: value })
      return found ? found.name : ""
    } else {
      return value
    }
  }

  const filterFields = [
    { name: 'name', label: 'name', },
    { name: 'major', label: 'major', },
    {
      name: 'vietseeds_generation', label: 'generation', options: GenerationFilterChoices,
    },
    { name: 'province_id', label: 'hometown', options: provinceList },
    { name: 'vietseeds_region', label: 'region', options: RegionChoices, },
  ]

  return (
    <Grid container spacing={2} alignItems='center' className={classes.root} direction="row">
      <Divider className={classes.divider} style={{marginBottom: 10}}/>
      <Grid container item xs={12} sm={2} direction="row" alignItems="center">
        <FilterIcon/>
        <Divider orientation="vertical" className={classes.verticalDivider}/>
        <span className={classes.title}>{t(`Filter by`)}</span>
      </Grid>
      <Grid container item style={{ flex: 1 }}>
        {
          filterFields.map(field => {
            const { name, label, options } = field
            return (
              <Grid key={field.name} style={{ flex: 1, marginRight: 10, minWidth: 100, }}>
                <FilterTextInput  
                  label={t(label)}
                  field={{ name }}
                  initialValue={getNameOfIdFromOptions(filter[field.name], options)}
                  options={options}
                  fullWidth
                  onChange={handleChangeFilter}
                  onEnter={handleClickFilter}
                />
              </Grid>
            )
          })
        }
      </Grid>
      <Grid item container xs={12} sm={2} justify="flex-end">
        <FilterButton onClick={handleClickFilter}>
          {t('filter')}
        </FilterButton>
      </Grid>
      <Divider className={classes.divider} style={{ marginTop: 10 }}/>
    </Grid>
  )
} 

export default Filter