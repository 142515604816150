import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      '& >ul': {
        justifyContent: 'center',
      },
      marginBottom: 30,
    },
  }
}))

const PaginationLink = (props) => {
  const classes = useStyles()

  const { page, count, handleChangePage } = props

  const smallScreen = useMediaQuery('(max-width:460px)');
  const mediumScreen = useMediaQuery('(max-width:660px)');
  
  return (
    <Pagination
      page={page}
      count={count}
      onChange={(e, page) => handleChangePage(page)}
      showFirstButton
      showLastButton
      hideNextButton
      hidePrevButton
      shape="rounded"
      color="primary"
      size={smallScreen ? "small" : (mediumScreen ? "medium" : "large")}
      className={classes.root}
    />
  );
}

export default PaginationLink
