import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {
  ScrollingProvider,
} from 'react-scroll-section';

import Menu from './Menu'
import PersonalInfo from './Sections/PersonalInfo'
import AcademicInfo from './Sections/AcademicInfo'
import PersonalSharing from './Sections/PersonalSharing'
import Essays from './Sections/Essays'


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
  },
  paperRoot: {
    width: '100%',
    height: '100%',
    borderRadius: 18,
    padding: '50px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  verticalDivider: {
    height: '100%',
  },
  dataContainerRoot: { 
    boxSizing: 'border-box', 
    borderLeft: '1px solid #C6C6C6',
    [theme.breakpoints.down('sm')]: {
      border: 'none'
    },
  },
}))

const ProfileContainer = (props) => {
  const classes = useStyles()

  const { studentInfos, provinceList, universityList } = props

  return (
    <Grid container item xs={12} className={classes.root}>
      <Paper className={classes.paperRoot} elevation={1}>
        <ScrollingProvider offset={-60}>
          <Grid container direction="row">
            <Grid xs={12} sm={4}>
              <Menu />
            </Grid>
            <Grid xs={12} sm={8} className={classes.dataContainerRoot}>
              <PersonalInfo studentInfos={studentInfos} provinceList={provinceList}/>
              <AcademicInfo studentInfos={studentInfos} provinceList={provinceList} universityList={universityList} />
              <PersonalSharing studentInfos={studentInfos} />
              <Essays studentInfos={studentInfos} />
            </Grid>
          </Grid>
        </ScrollingProvider>
      </Paper>
    </Grid>
  )
}

export default ProfileContainer
