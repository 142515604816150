import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

import DonateWay from './components/DonateWay'
import PropsList from './components/PropsList'
import FurtherInformation from './components/FurtherInformation'
import PaypalInfo from './components/PaypalInfo'
import QRWalletInfo from './components/QRWalletInfo'

import BannerImage from '../../static/images/banner.png'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 50,
  },
  bannerImage: {
    width: '100%',
    height: 'auto'
  },
  titleContainer: {
    textAlign: 'center',
  },
  title: {
    fontSize: 42,
    color: '#000',
    fontWeight: 'bold',
    lineHeight: '56px',
  },
  titleHighlight: {
    color: '#85BE1A',
  },
  subtitle: {
    fontSize: 18,
    color: '#222A41',
    lineHeight: '24px',
    marginTop: 20,
    maxWidth: '70%',
    margin: 'auto',
  },
  container: {
    marginTop: 30,
  }
}))

const donationWays = [
  {
    image: require('../../static/images/donate/vietcombank.png'),
    name: 'Vietcombank Account Detail',
    children: <PropsList propsList={[
      { value: 'You can set up your Vietcombank account as a recurring transfer to VietSeeds, based on your preference(monthly, quarterly, bi-annually or just one-time payment)' },
      { value: `VietSeeds' banking account type is non-profit, which is not allowed to have transaction via Internet Banking.` },
      { name: 'Account name', value: 'VietSeed Foundation Inc.' },
      { name: 'Account number', value: '0371000451704' },
      { name: 'Branch', value: 'Vietcombank Tan Dinh' },
      { name: 'Swiftcode', value: 'BFTVVNVX007' },
    ]}/>
  },
  // {
  //   image: require('../../static/images/donate/vietcombank.png'),
  //   name: 'Vietcombank Account Detail',
  //   children: <PropsList propsList={[
  //     { value: `VietSeeds' banking account type is non-profit, which is not allowed to have transaction via Internet Banking. If you wish to use IB, please transfer via Ms.Cát Tường’s banking account.` },
  //     { name: 'Account name', value: 'Huyền Tôn Nữ Cát Tường' },
  //     { name: 'Account number', value: '0071002541103' },
  //     { name: 'Branch', value: 'Vietcombank Tan Dinh' },
  //     { name: 'Swiftcode', value: 'BFTVVNVX007' },
  //   ]} />
  // },
  {
    image: require('../../static/images/donate/paypal.png'),
    name: 'Donate via PayPal',
    children: <PaypalInfo/>
  },
  {
    image: require('../../static/images/donate/wellsfargo.png'),
    name: 'Wells Fargo Bank Account Details',
    children: <PropsList propsList={[
      { name: 'Account name', value: 'VietSeed Foundation Inc.' },
      { name: 'Account number', value: '8774079498' },
      { name: 'Zipcode', value: '94107' },
      {
        name: 'Routing number', 
        value: `Direct deposits, Electronic Payments colon 12104 Wire Transfers - Domestic colon 121000248` 
      },
      { name: 'Swiftcode', value: 'WFBIUS6S (for international wire transfer)' },
    ]} />
  },
  {
    image: require('../../static/images/donate/detail.png'),
    children: <FurtherInformation />
  },
  // {
  //   image: require('../../static/images/donate/momo-logo.png'),
  //   name: 'Donate via Momo Wallet',
  //   children: <QRWalletInfo address={'X'} image={require('../../static/images/donate/momo-qr.png')} />
  // },
  // {
  //   image: require('../../static/images/donate/zalopay-logo.png'),
  //   name: 'Donate via Zalopay Wallet',
  //   children: <QRWalletInfo address={'X'} image={require('../../static/images/donate/zalopay-qr.png')} />
  // },
  {
    image: require('../../static/images/donate/btc-logo.png'),
    name: 'Donate via BTC Wallet',
    children: <QRWalletInfo address={'13MPWUrTK9v9sF636BZMkkRWDtmWznwZYY'} image={require('../../static/images/donate/btc-qr.png')} />
  },
  {
    image: require('../../static/images/donate/eth-logo.png'),
    name: 'Donate via ETH Wallet',
    children: <QRWalletInfo address={'0xc59a56551a86dbbd22a04232f055786459add2e6'} image={require('../../static/images/donate/eth-qr.png')} />
  },
  {
    image: require('../../static/images/donate/bnb-logo.png'),
    name: 'Donate via BNB Wallet',
    children: <QRWalletInfo 
      address={'bnb136ns6lfw4zs5hg4n85vdthaad7hq5m4gtkgf23'}
      image={require('../../static/images/donate/bnb-qr.png')} 
      propsList={[
        { name: 'BNB memo', value: '142496663' },
      ]}
    />
  },
  {
    image: require('../../static/images/donate/ltc-logo.png'),
    name: 'Donate via LTC Wallet',
    children: <QRWalletInfo
      address={'LP8YMmFM9gRvLTeFZccqn3YJBysExryu7m'}
      image={require('../../static/images/donate/ltc-qr.png')}
    />
  },
  {
    image: require('../../static/images/donate/checks.png'),
    name: 'Checks',
    children: <PropsList propsList={[
      { name: 'Pay to the order of', value: 'VietSeeds Foundation Inc.' },
      { name: 'Address', value: '139 Old Orchard Drive, Los Gatos CA 95032, USA' },
    ]} />
  },
]

const DonationGuide = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('donation_guide')
  return (
    <>
      <img src={BannerImage} alt='banner' className={classes.bannerImage} />
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            {t(`Ways to`)} <span className={classes.titleHighlight}>{t('Donate')}</span>
          </div>
          <div className={classes.subtitle}>
            {t(`We appreciate the community's support for more than 10 years, here are all the ways you can help colon`)}
          </div>
        </div>
        <Grid container spacing={5} className={classes.container}>
          {
            donationWays.map((donateWay, index) => {
              return (
                <DonateWay key={`donate-way-${index}`} {...donateWay} />
              )
            })
          }
        </Grid>
      </div>
    </>
  )
}


const mapStateToProps = ({ general }) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DonationGuide)
