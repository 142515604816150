import { all } from 'redux-saga/effects';

import {
  getProvinceListWatcher,
  getUniversityListWatcher,
  getNationListWatcher,
} from './generalSaga'

import {
  getStudentListWatcher,
  getStudentInfosWatcher,
} from './studentSaga'

export default function* rootSaga() {
  yield all([
    // general
    getProvinceListWatcher(),
    getUniversityListWatcher(),
    getNationListWatcher(),

    // student
    getStudentListWatcher(),
    getStudentInfosWatcher(),
  ]);
}