import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next"

import Logo from './Logo'
import LanguageSelector from './LanguageSelector'
import NavBarItems from './NavBarItems'

import AuthProvider from '../../connectors/AuthProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 50,
  },
  centerSection: {
    flexGrow: 1,
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContainer: {
  }
}));

const DesktopLayout = (props) => {
  const { t, i18n } = useTranslation('layout');
  const classes = useStyles()
  const { children } = props

  return (
    <div className={classes.root}>
      <AppBar position="sticky" color="white.main">
        <Toolbar>
          <div className={classes.leftSection}>
            <Logo href='/student-list'/>
            <LanguageSelector style={{ marginLeft: 50 }}/>
          </div>
          <div className={classes.centerSection}>

          </div>
          <div className={classes.rightSection}>
            <NavBarItems/>
            {
              AuthProvider.checkAuth() ? 
                <Button color="inherit" onClick={() => AuthProvider.logout()} href={`/login`}>{t('log-out')}</Button>
                :
                <React.Fragment>
                  <Button color="inherit" href={`/register`}>{t('register')}</Button>
                  <Button color="inherit" href={`/login`}>{t('log-in')}</Button>
                </React.Fragment>
            }
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.mainContainer}>
        {children}
      </div>
    </div>
  );
}

export default DesktopLayout
